import React from "react";
import RootContent from "./content.root";
type TypesInputFile = {
  id?: string;
  accept?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  label: string;
};
export const InputFile = React.forwardRef<HTMLInputElement, TypesInputFile>(
  (props, ref) => {
    const { label, ...rest } = props;
    return (
      <RootContent label={label}>
        <input ref={ref} type="file" {...rest} />
      </RootContent>
    );
  }
);
export default InputFile;
