import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants/colors";

type TypesBgColor = "primary" | "success";

type TypesButton = {
  type?: "button" | "submit" | "reset";
  bgColor?: TypesBgColor;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonStyled = styled("button")(
  ({ bgColor }: { bgColor?: TypesBgColor }) => ({
    backgroundColor: bgColor === "primary" ? colors.eva_50 : "gray",
    color: "#fff",
    border: "1px solid transparent",
    boxShadow: "0 2px 0 rgb(0 0 0 / 2%)",
    borderRadius: 4,
    fontSize: 14,
    padding: "0 15px",
    height: 32,
    touchAction: "manipulation",
    cursor: "pointer",
    transition: "all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1)",
    "&:hover": {
      backgroundColor: "#fff",
      color: bgColor === "primary" ? colors.eva_50 : "gray",
      borderColor: bgColor === "primary" ? colors.eva_50 : "gray",
    },
  })
);

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<TypesButton>
>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <ButtonStyled ref={ref} {...rest}>
      {children}
    </ButtonStyled>
  );
});
