import { OnInitialize } from "overmind";
import { ConfigurationKey } from "./graphql-types/graphql-global-types";
import { buildHttpOptions } from "../utils/httpOptionsBuilder"

const onInitialize: OnInitialize = async (
  { state, actions, effects },
  instance
) => {
  const httpOptions = buildHttpOptions({ state, actions });
  effects.api.initialize(httpOptions,
    (resp: any) => {
      const newToken = resp?.extensions?.Token;
      if (newToken) actions.updateToken(newToken);
      if (resp?.errorCode === 401) {
        actions.logout();
      }
    }
  );

  const token = window.localStorage.getItem('auth_token');
  const refreshToken = window.localStorage.getItem('refresh_token');
  state.authToken = token;
  state.refreshToken = refreshToken;
  if (token) {
    /* Fetching the configurations from the server. */
    await actions.fetchConfigurations({
      keys: [
        ConfigurationKey.COUNTRY_CODE,
        ConfigurationKey.CURRENCY_PRECISION,
        ConfigurationKey.COUNTRY_NAME,
        ConfigurationKey.MONEY_CODE,
        ConfigurationKey.DEFAULT_IVA,
      ],
    });
    /* Fetching the initial data from the server. */
    await actions.fetchInitialData();
  }
};

export default onInitialize;
