import React from "react";
import { CSSProperties } from "styled-components";
import { Circle } from "../figures";
import { IconLibrary, PropsNameIcons } from "../icons";
import { Paragraph } from "../paragraph";
import RootTag, { PropsColorsTag } from "./tag.root";

interface StateTag {
  color?: PropsColorsTag;
  icon?: PropsNameIcons;
  style?: React.CSSProperties;
}

export const Tag: React.FC<StateTag> = ({ color, icon, style, children }) => {
  const stylesParagraph: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <RootTag color={color} style={style}>
      <Paragraph
        variant="text"
        color={color === "blue" ? "darkblue" : "black"}
        style={stylesParagraph}
        strong
      >
        {icon ? (
          <>
            {color === "default" ? (
              <Circle style={{ width: 20, height: 20, padding: 5 }}>
                <IconLibrary name={icon} />
              </Circle>
            ) : (
              <IconLibrary name={icon} />
            )}
            {children}
          </>
        ) : (
          children
        )}
      </Paragraph>
    </RootTag>
  );
};
export * from "./tag.root";
