import CheckCircleSVG from "../assets/svgs/check_circle.svg";
import NewProviderSVG from "../assets/svgs/medals/NewProvider.svg";
import OutstandingProviderSVG from "../assets/svgs/medals/OutstandingProvider.svg";
import PlusProviderSVG from "../assets/svgs/medals/PlusProvider.svg";
import MedalListSVG from "../assets/svgs/medals/Medals-list.svg";
import ProfileDefaultSVG from "../assets/svgs/medals/ProfileDefault.svg";
import ElenasIconSVG from "../assets/svgs/medals/MedalsCount.svg";
import BackgroundDefaultSVG from "../assets/svgs/medals/BackgroundDefault.svg";
import MedalTimeSVG from "../assets/svgs/medals/MedalTimeUnlock.svg";
import MedalTimeLockSVG from "../assets/svgs/medals/MedalTimeLock.svg";
import MedalPriceSVG from "../assets/svgs/medals/MedalPriceUnlock.svg";
import MedalPriceLockSVG from "../assets/svgs/medals/MedalPriceLock.svg";
import MedalSupplySVG from "../assets/svgs/medals/MedalSuplyUnlock.svg";
import MedalSupplyLockSVG from "../assets/svgs/medals/MedalSuplyLock.svg";
import MedalProductsSVG from "../assets/svgs/medals/MedaProductsUnLock.svg";
import MedalProductsLockSVG from "../assets/svgs/medals/MedalProductsLock.svg";
import FlameElenasSVG from "../assets/svgs/flameElenas.svg";
import GiftLeftSVG from "../assets/svgs/gift_left.svg";
import GiftRightSVG from "../assets/svgs/gift_right.svg";

type TypesSVG = {
  name:
    | "CheckCircle"
    | "NewProvider"
    | "OutstandingProvider"
    | "PlusProvider"
    | "MedalList"
    | "ProfileDefault"
    | "ElenasIcon"
    | "BackgroundDefault"
    | "MedalTime"
    | "MedalPrice"
    | "MedalSupply"
    | "MedalProducts"
    | "MedalTimeLock"
    | "MedalPriceLock"
    | "MedalSupplyLock"
    | "MedalProductsLock"
    | "FlameElenas"
    | "GiftLeft"
    | "GiftRight";
};

const SVGLibrary = ({ name = "CheckCircle" }: TypesSVG) => {
  const Icons = {
    CheckCircle: CheckCircleSVG,
    NewProvider: NewProviderSVG,
    OutstandingProvider: OutstandingProviderSVG,
    PlusProvider: PlusProviderSVG,
    MedalList: MedalListSVG,
    ProfileDefault: ProfileDefaultSVG,
    ElenasIcon: ElenasIconSVG,
    BackgroundDefault: BackgroundDefaultSVG,
    MedalTime: MedalTimeSVG,
    MedalPrice: MedalPriceSVG,
    MedalSupply: MedalSupplySVG,
    MedalProducts: MedalProductsSVG,
    MedalTimeLock: MedalTimeLockSVG,
    MedalPriceLock: MedalPriceLockSVG,
    MedalSupplyLock: MedalSupplyLockSVG,
    MedalProductsLock: MedalProductsLockSVG,
    FlameElenas: FlameElenasSVG,
    GiftLeft: GiftLeftSVG,
    GiftRight: GiftRightSVG,
  };
  return Icons[name];
};

export default SVGLibrary;
