import React, { useState } from 'react'
import { Form, Icon, Input, Button, notification } from "antd";
import { Link } from "react-router-dom";
import { useOvermind } from "../../overmind/index";

const FormEmail = ({ form, setActiveComponent, setEmail, email })  => {
  // STATE
  const [loading, setLoading] = useState(false);

  // OVERMIND
  const {
    actions: { sendPasswordResetCode },
  } = useOvermind();

  const { getFieldDecorator } = form;

  const formSubmit = async(event) => {
    event.preventDefault()
    setLoading(true)
    const isResetCode = await sendPasswordResetCode({email})

    if (isResetCode) {
      setLoading(false);
      setActiveComponent('sendCode');
    } else {
      setLoading(false);
      notification.error({
        message: `El correo ${email} no se encuentra registrado`,
      });
    }
  }

  return (
    <Form onSubmit={formSubmit} className="login-form" autocomplete="off">
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [
            {
              required: true,
              message: "Por favor ingrese su correo electrónico",
            },
          ],
        })(
          <Input
            prefix={<Icon type="mail" style={{ color: "#A3A6CC" }} />}
            type="email"
            placeholder="Tu correo electrónico"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        )}
      </Form.Item>

      <Form.Item style={{ marginBottom: 10 }}>
        <Button block type="primary" htmlType="submit" loading={loading}>
          Enviar correo
        </Button>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Link to="/auth/signin">Regresar a inicio de sesión</Link>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(FormEmail)
