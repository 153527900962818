import { Constraints, MktMaintenanceMode, MktMaintenanceModeTest } from "./types";

export type StateFlags = {
  loading: boolean;
  maintenance: boolean;
  all: {
    mkt_maintenance_mode: MktMaintenanceMode;
    mkt_maintenance_mode_test: MktMaintenanceModeTest;
  };
  constraints: Constraints;
  autoTrackedFlags: { [key: string]: string };
  error: boolean;
};

export const flags: StateFlags = {
  loading: true,
  maintenance: false,
  all: {
    mkt_maintenance_mode: {
      flagKey: "k8j4qhmabfv4zhkn7",
      flagName: "mkt_maintenance_mode",
    },
    mkt_maintenance_mode_test: {
      flagKey: "kpd1mfsmt7dk149qr",
      flagName: "mkt_maintenance_mode_test",
    },
  },
  constraints: {
    is_staging: process?.env?.DEV_ENV === "staging",
    environment: process?.env?.DEV_ENV ?? "",
    elite: true,
    friends_family: false,
  },
  autoTrackedFlags: {},
  error: false,
};
