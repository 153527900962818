import React, { useState, useEffect, useCallback } from "react";
import { Form, Icon, Input, Button, Typography, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import styles from "./login.style";
import { useOvermind } from "../../overmind/index";

const SigninPage = (props) => {
  // OVERMIND
  const {
    actions: { login },
    state: {
      flags: {
        all: { mkt_maintenance_mode },
        error,
      },
      authToken,
    },
  } = useOvermind();
  const history = useHistory();
  const { getFieldDecorator } = props.form;
  // STATE
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const redirectToDashboard = useCallback(() => {
    goToMarketPlace();

    function goToMarketPlace() {
      // /* Checking if the user is logged in and if the user is logged in, it is checking if the
      // maintenance mode is on or off. If it is on, it redirects to the maintenance page, if it is
      // off, it redirects to the products page. */
      if (authToken && mkt_maintenance_mode.variant) {
        const variant =
          typeof mkt_maintenance_mode.variant === "undefined"
            ? "off"
            : mkt_maintenance_mode.variant;
        variant === "off"
          ? history.push("/protected/products")
          : history.push("/protected/maintenance");
      } else if (authToken && !mkt_maintenance_mode.variant && error) {
        history.push("/protected/products");
      }
    }
  }, [mkt_maintenance_mode, error, authToken, history]);

  useEffect(() => redirectToDashboard(), [redirectToDashboard]);

  // INTERNAL VARS
  const { Title } = Typography;

  const formSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const errors = await login({ email, password });
      if (errors) {
        setLoading(false);
        const message = errors.message
          ? errors.message
          : errors.errors.length
          ? errors.errors[0].message
          : "Algo anda mal";
        notification.error({
          message,
        });
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Title style={styles.authTitle} level={3}>
        Iniciar sesión
      </Title>
      <div style={styles.card}>
        <div style={styles.message} />
        <div style={styles.formContainer}>
          <p style={{ fontSize: 18 }}>
            Accede al Marketplace de Elenas, maneja tu inventario de productos y
            mantente al tanto de todas tus ordenes.
          </p>
          <Form onSubmit={formSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingrese su correo electrónico",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" style={{ color: "#A3A6CC" }} />}
                  type="email"
                  placeholder="Tu correo electrónico"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingrese su contraseña",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "#A3A6CC" }} />}
                  type="password"
                  placeholder="Tu contraseña"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              )}
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Iniciar sesión
              </Button>
              <div>
                <span>
                  ¿No tienes una cuenta?{" "}
                  <Link to="/auth/signup">Registrar aquí</Link>{" "}
                </span>
              </div>
            </Form.Item>
          </Form>

          <Form.Item style={{ marginBottom: 0 }}>
            <Link to="/auth/reset-password">Restablecer contraseña</Link>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default Form.create()(SigninPage);
