import React from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "../../../constants/colors";

type colors = "blue" | "yellow" | "darkblue";

interface TypesRootCircle {
  color?: colors;
}

export const RootCircle = styled.span<TypesRootCircle>`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.color === "yellow"
      ? `background: ${colors.cheese_80};
            color: ${colors.cheese_50};`
      : props.color === "darkblue"
      ? `background: ${colors.pacific_70};
            color: ${colors.pacific_20};`
      : `background: ${colors.pacific_50};
        color: ${colors.white};
        `}
`;

interface StateCircle {
  style?: CSSProperties;
  color?: colors;
}

export const Circle: React.FC<StateCircle> = ({ children, ...props }) => {
  return <RootCircle {...props}>{children}</RootCircle>;
};
