export default {
  welcomeContainer: {},
  container: {
    minHeight: "auto",
  },
  header: {
    position: "absolute",
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    paddingTop: 16,
  },
  layout: {
    flex: 1,
    backgroundColor: "#F0F1FF",
  },
  banner: {},
  bannerTextWrap: {},
  bannerTitle: {
    color: "#fff",
  },
  bannerText: {
    color: "#fff",
    fontSize: 20,
  },
  bannerImage: {
    maxWidth: "100%",
    height: "auto",
  },
  bannerButton: {
    fontSize: 24,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgGradient: {
    backgroundColor: "#5b2ad0",
    background: "linear-gradient(45deg, #5b2ad0 0%, #5b2ad0 50%, #0089ff 100%)",
  },
  textCenter: {
    textAlign: "center",
  },
  textWhite: {
    color: "#fff",
  },
  valuePropImg: {
    marginBottom: 48,
    width: "100%",
    maxWidth: 240,
  },
  valuePropTitle: {
    marginBottom: 24,
  },
  valuePropButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
  },
  section: {
    padding: 50,
    paddingBottom: 88,
    paddingTop: 80,
  },
  sectionTextWrap: {
    paddingTop: 80,
  },
  sectionTitle: {
    fontSize: 32,
    lineHeight: "32px",
  },
  sectionText: {
    fontSize: 20,
  },
  sectionImage: {
    maxWidth: "100%",
    height: "auto",
  },
  sectionButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
    paddingLeft: 40,
    paddingRight: 40,
  },
  sectionInput: {
    marginBottom: 24,
  },
};
