import React, { Component } from "react";
import { Button, Divider, Layout } from "antd";
import ModalFooter from './modal.footer'

import styles from "./footer.style";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    }
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  showModal() {
    this.setState({ isModalVisible: true })
  }

  handleCancel() {
    this.setState({ isModalVisible: false})
  }

  render() {
    // link
    const frequentlyQuestionsLink =
      "https://www.notion.so/FAQs-para-Aliados-Comerciales-adde1a21a2f34ce4a4042e697dba5d80";

    // Component
    const { Footer } = Layout;

    return (
      <div>
        <Footer style={styles.footer}>
          <div>
            <img
              alt=""
              src={require("../../assets/images/support.png")}
              style={styles.support}
            />
            <p style={styles.textTitleHelp}>¿Tienes alguna duda?</p>
            <span style={styles.textSubTitleHelp}>
              Para nosotros es un gusto ayudarte :)
            </span>
            <div>
              <Button
                style={{
                  ...styles.buttonOutlined,
                  ...styles.buttonOutlinedBlue,
                }}
                onClick={() => window.open(frequentlyQuestionsLink, "_blank")}
              >
                Preguntas frecuentes
              </Button>
              <Button
                style={{
                  ...styles.buttonOutlined,
                  ...styles.buttonOutlinedBlue,
                }}
                onClick={this.showModal}
              >
                Información legal
              </Button>
            </div>
          </div>
          <Divider />
          <img alt="" src={require("../../assets/images/logo-footer.svg")} />
        </Footer>
        <ModalFooter 
          isModalVisible={this.state.isModalVisible}
          maskClosable="true"
          handleCancel={this.handleCancel}
        />
      </div>
    );
  }
}

export default Footer;
