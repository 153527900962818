import { Action, AsyncAction } from "overmind";
import history from "../../utils/history";
import {
  LoginVariables,
  Login_login_ValidationErrors,
} from "../effects/api/graphql-types/Login";
import {
  SignUpVariables,
  SignUp_signUp_ValidationErrors,
} from "../effects/api/graphql-types/SignUp";
import { sendPasswordResetCodeVariables } from "../effects/api/graphql-types/sendPasswordResetCode";
import {
  resetPasswordVariables,
  resetPassword_resetPassword,
} from '../effects/api/graphql-types/resetPassword';
import { ConfigurationKey } from "../graphql-types/graphql-global-types";

export const login: AsyncAction<
  LoginVariables,
  Login_login_ValidationErrors | void
> = async ({ state, actions, effects }, variables) => {
  try {
    actions.resetState();
    const { login } = await effects.api.mutations.login(variables);
    if ("errors" in login) {
      return login;
    }
    await actions.updateToken({
      token: login?.token,
      refreshToken: login?.refresh_token,
    });
    /* Fetching the configurations from the server. */
    await actions.fetchConfigurations({
      keys: [
        ConfigurationKey.COUNTRY_CODE,
        ConfigurationKey.CURRENCY_PRECISION,
        ConfigurationKey.COUNTRY_NAME,
        ConfigurationKey.MONEY_CODE,
        ConfigurationKey.DEFAULT_IVA,
      ],
    });
    /* Fetching the user data from the server. */
    await actions.fetchInitialData();
  } catch (error) {
    console.error("(Login) -----> Mutation error: ", error);
    return { message: "Ocurrió un error", errors: [] };
  }
};

export const sendPasswordResetCode: AsyncAction<
  sendPasswordResetCodeVariables,
  boolean
> = async ({ effects }, { email }) => {
  try {
    const { sendPasswordResetCodeEmail } =
      await effects.api.mutations.sendPasswordResetCodeM({
        email,
      });
    return sendPasswordResetCodeEmail;
  } catch (error) {
    console.error("(sendPasswordResetCode) -----> Mutation error: ", error);
    return false;
  }
};

export const triggerPasswordReset: AsyncAction<resetPasswordVariables> = async (
  { actions, effects },
  { email, code, newPassword }
) => {
  const { resetPasswordByEmail } = await effects.api.mutations.resetPasswordM({
    email,
    code,
    newPassword,
  });
  if (resetPasswordByEmail.success) {
    await actions.updateToken({
      token: resetPasswordByEmail.authInfo!.token,
      refreshToken: resetPasswordByEmail.authInfo!.refresh_token,
    });
    actions.setResetPasswordSuccess({
      resetPasswordResponse: resetPasswordByEmail,
    });
    actions.fetchInitialData();
  } else {
    actions.setResetPasswordError({
      resetPasswordResponse: resetPasswordByEmail,
    });
  }
};

export const setResetPasswordSuccess: Action<{
  resetPasswordResponse: resetPassword_resetPassword;
}> = ({ state }, { resetPasswordResponse }) => {
  state.resetPassworSuccess = resetPasswordResponse;
};

export const setResetPasswordError: Action<{
  resetPasswordResponse: resetPassword_resetPassword;
}> = ({ state }, { resetPasswordResponse }) => {
  state.resetPasswordError = resetPasswordResponse;
};

export const updateToken: AsyncAction<{
  token: string;
  refreshToken: string | null;
}> = async ({ state, effects }, { token, refreshToken }) => {
  if (token) {
    state.authToken = token;
    state.refreshToken = refreshToken;
    effects.storage.setItem("auth_token", token);
    effects.storage.setItem("refresh_token", refreshToken ?? "");
  }
};

export const signUp: AsyncAction<
  SignUpVariables,
  SignUp_signUp_ValidationErrors | void
> = async ({ state, actions, effects }, variables) => {
  try {
    const { signUp } = await effects.api.mutations.signUp(variables);
    if ("errors" in signUp) {
      return signUp;
    }

    await actions.updateToken({
      token: signUp?.token,
      refreshToken: signUp?.refresh_token,
    });
    state.authToken = signUp.token;
    /* Fetching the configurations from the server. */
    await actions.fetchConfigurations({
      keys: [
        ConfigurationKey.COUNTRY_CODE,
        ConfigurationKey.CURRENCY_PRECISION,
        ConfigurationKey.COUNTRY_NAME,
        ConfigurationKey.MONEY_CODE,
        ConfigurationKey.DEFAULT_IVA,
      ],
    });
    /* Fetching the user data from the server. */
    actions.fetchInitialData();
    history.push(`/protected/profile`);
  } catch (error) {
    console.error("(SignUp) -----> Mutation error: ", error);
    return { message: "Ocurrió un error", errors: [] };
  }
};

export const refreshToken: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  const response = await effects.api.mutations.refreshTokenM({
    token: state.refreshToken!,
  });
  actions.updateToken({
    token: response.refreshToken!.token!,
    refreshToken: response.refreshToken!.refresh_token!,
  });
};

export const logout: Action<void, void> = ({ state, effects }, variables) => {
  state.authToken = null;
  state.vendorInfo = null;
  state.vendorMedals = [];
  state.topProductReviews = [];
  state.productReviewsAvg = null;
  state.products = {};
  state.flags = {
    loading: false,
    maintenance: false,
    all: {
      mkt_maintenance_mode: {
        flagKey: "k8j4qhmabfv4zhkn7",
        flagName: "mkt_maintenance_mode",
      },
      mkt_maintenance_mode_test: {
        flagKey: 'kpd1mfsmt7dk149qr',
        flagName: 'mkt_maintenance_mode_test',
      },
    },
    constraints: {
      is_staging: false,
      environment: "",
      elite: false,
      friends_family: false,
    },
    autoTrackedFlags: {},
    error: false,
  };
  effects.storage.removeItem("auth_token");
  effects.storage.removeItem("refresh_token");
  sessionStorage.removeItem('user');
  sessionStorage.clear();
  localStorage.clear();
};
