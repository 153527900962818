import React, { useEffect } from "react";
import { Modal } from "antd";
import { Icon } from 'antd';
import { useOvermind } from "../../overmind/index";

import styles from "./modal.style";

const ModalFooter = ({isModalVisible, handleCancel}) => {
  const {
    state: { contracts },
    actions: {
      fetchContracts
    }
  } = useOvermind();

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  if (contracts) {
    return (
      <Modal
        title="Información legal"
        visible={isModalVisible}
        footer={[]}
        onCancel={handleCancel}
      >
        {contracts.map((contract, key) => (
          <div key={key}>
            <a
              key={contract.name + contract.id}
              href={contract.contract_file_url}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={styles.item}
            >
              <Icon type="cloud-download" />
              {contract.name}
            </a>
            <br />
          </div>
        ))}
      </Modal>
    );
  }
  else {
    return (<p> </p>)
  }
}

export default ModalFooter;