// import axios from "axios";
import { Constraints, Flag } from "../../flags/types";
import { API } from "../../../config/axios";

const flagr = () => {
  /**
   * It takes an object with a length property and returns a string of random characters of the
   * specified length.
   * @param  - { length: number; }
   * @returns A string of random characters.
   */
  function createIdApplication({ length }: { length: number }): string {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  /**
   * `fetchAll` takes an array of flags and a constraints object and returns a promise that resolves to
   * an array of flags
   * @param {Flag[]} existingFlags - An array of flags that you've already fetched.
   * @param {Constraints} constraints - Constraints
   * @returns An array of objects.
   */
  async function fetchAll(existingFlags: Flag[], constraints: Constraints) {
    const params = {
      entities: [
        {
          entityID: createIdApplication({ length: 16 }),
          // entityType: "1",
          entityContext: constraints,
        },
      ],
      flagkeys: existingFlags.map((flag) => flag.flagKey),
      flagTags: ["auto-tracked"],
    };
    try {
      const res = await API.post("/evaluation/batch", params, {
        timeout: 2000,
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  return {
    fetchAll,
  };
};

export default flagr();
