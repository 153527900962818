import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import { TypesTitle } from "../@types";
export const RootTitle = styled.span<TypesTitle>`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) =>
    props.variant === "h1"
      ? "28px"
      : props.variant === "h2"
      ? "24px"
      : props.variant === "h3"
      ? "20px"
      : props.variant === "h4"
      ? "18px"
      : props.variant === "h5"
      ? "16px"
      : "12px"};
  color: ${(props) =>
    props.color === "white"
      ? colors.white_100
      : props.color === "darkblue"
      ? colors.pacific_70
      : props.color === "gray"
      ? colors.stone_60
      : props.color === "blue"
      ? colors.pacific_50
      : props.color === "violet"
      ? colors.eva_50
      : colors.stone_80};
`;
