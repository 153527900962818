import { AsyncAction } from "overmind";
/**
 * getUpcomingPayment
 */
export const getUpcomingPayment: AsyncAction<{
  page: number;
  perPage: number;
}> = async ({ state, effects }, { page, perPage }) => {
  try {
    // Loading
    state.payments.loadingOrdersUpcomingPayment = true;
    // Async
    const { upcomingPayment } = await effects.api.queries.getUpcomingPaymentQ({
      page,
      perPage,
    });
    state.payments.ordersUpcomingPayment = upcomingPayment;
    // End
    state.payments.loadingOrdersUpcomingPayment = false;
  } catch (error) {
    console.error(error);
    // Loading
    state.payments.loadingOrdersUpcomingPayment = false;
  }
};

/**
 * getPendingOrderEarnings
 */
export const getPendingOrderEarnings: AsyncAction<{
  page: number;
  perPage: number;
}> = async ({ state, effects }, { page, perPage }) => {
  try {
    // Loading
    state.payments.loadingOrdersPendingEarnings = true;
    // Async
    const { pendingOrderEarnings } =
    await effects.api.queries.getPendingOrderEarningsQ({ page, perPage });
    state.payments.ordersPendingEarnings = pendingOrderEarnings;
    // End
    state.payments.loadingOrdersPendingEarnings = false;
  } catch (error) {
    console.error(error);
    // Loading
    state.payments.loadingOrdersPendingEarnings = false;
  }
};

/**
 * getReceivedPayments
 */
export const getReceivedPayments: AsyncAction<{
  page: number;
  perPage: number;
}> = async ({ state, effects }, { page, perPage }) => {
  try {
    // Loading
    state.payments.loadingOrdersReceivedPayments = true;
    // Async
    const { receivedPayments } = await effects.api.queries.getReceivedPaymentsQ(
      {
        page,
        perPage,
      }
    );
    state.payments.ordersReceivedPayments = receivedPayments;
    // End
    state.payments.loadingOrdersReceivedPayments = false;
  } catch (error) {
    console.error(error);
    // Loading
    state.payments.loadingOrdersReceivedPayments = false;
  }
};

/**
 * sendAccountingReports
 */
export const sendAccountingReports: AsyncAction<
  {
    date: string[];
  },
  {
    response: boolean;
    recipient: string;
  }
> = async ({ state, effects }, { date }) => {
  try {
    state.payments.loadingSendAccountingReports = true;
    const { sendAccountingReports } =
      await effects.api.queries.sendAccountingReportsQ({ date });
    state.payments.loadingSendAccountingReports = false;
    return sendAccountingReports;
  } catch (error) {
    console.error(error);
    state.payments.loadingSendAccountingReports = false;
    return { response: false, recipient: "" };
  }
};

/**
 * getVendorPaidOrders
 */
export const getVendorPaidOrders: AsyncAction<{
  page: number;
  perPage: number;
}> = async ({ state, effects }, { page, perPage }) => {
  try {
    // Loading
    state.payments.loadingOrdersVendorPaid = true;
    // Async
    const { vendorPaidOrders } = await effects.api.queries.getVendorPaidOrdersQ(
      {
        page,
        perPage,
      }
    );
    state.payments.ordersVendorPaid = vendorPaidOrders;
    // End
    state.payments.loadingOrdersVendorPaid = false;
  } catch (error) {
    console.error(error);
    state.payments.loadingOrdersVendorPaid = false;
  }
};

export const fetchReports: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { fetchReports } = await effects.api.queries.getReports();
    state.reports = fetchReports;
  } catch (error) {
    console.error("(FetchReports) -----> Error: ", error);
  }
};