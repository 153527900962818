export default {
  mainContainer: {
    padding: "0 20px",
    backgroundColor: "#f0f1ff",
  },
  mainContainerMaintenance: {
    padding: "0 0px",
    backgroundColor: "#f0f1ff",
  },
  header: {
    backgroundColor: "#5b2ad0",
    background:
      "transparent linear-gradient(107deg, #5B2AD0 0%, #0089FF 100%, #0089FF 100%, #0089FF 100%) 0% 0% no-repeat padding-box",
    borderRadius: "24px",
    paddingTop: 16,
    paddingBottom: 180,
    height: "auto",
  },
};
