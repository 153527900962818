export default {
  marketplaceLogo: {
    color: "#fff",
  },
  actions: {
    textAlign: "right",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
  },
  menu: {
    lineHeight: "64px",
    backgroundColor: "transparent",
    borderBottom: "none",
  },
  menuRight: {
    lineHeight: "64px",
    backgroundColor: "transparent",
    borderBottom: "none",
    textAlign: "right",
  },
  loginButton: {
    cursor: "pointer",
    fontSize: 14,
    padding: 10,
    fontWeight: "bold",
  },
  signUpButton: {
    cursor: "pointer",
    fontSize: 14,
    padding: 10,
    fontWeight: "bold",
  },
  brandOptions: {
    display: "flex",
  },
  brandLogo: {
    color: "#fff",
  },
  brandName: {
    maxWidth: 80,
    whiteSpace: "no-wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
    lineHeight: 1,
    paddingBottom: 1,
    marginBottom: -2,
    marginLeft: "5px",
  },
  optionButtons: {},
  dropdownItem: {},
  containerRightMenus: {
    display: "flex",
  },
  containerRightItemMenus: {
    display: "flex",
    alignItems: "center",
  },
  alertDot: {
    margin: -26,
    marginLeft: 15,
    top: -9,
   },
  notifyIcon: {
    marginRight: "10px",
  },
};
