import {
  faClipboardList,
  faCoins,
  faGlobe,
  faHouseUser,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { AsyncAction } from "overmind";
import { CommunicationAlertsVariables } from "../effects/api/graphql-types/CommunicationAlerts";
import { MarkCommunicationAsReadVariables } from "../effects/api/graphql-types/MarkCommunicationAsRead";

const DEFAUL_PER_PAGE = 10;

const assignedConfig = {
  GENERAL: {
    translation: "General",
    icon: faGlobe,
    color: "#527EA3",
    redirectURL: null,
  },
  ORDERS: {
    translation: "Órdenes",
    icon: faTruck,
    color: "#AFB218",
    redirectURL: "/protected/orders",
  },
  PAYMENTS: {
    translation: "Pagos",
    icon: faCoins,
    color: "#B25218",
    redirectURL: "/protected/payments",
  },
  PROFILE: {
    translation: "Perfil",
    icon: faHouseUser,
    color: "#5BB218",
    redirectURL: "/protected/profile",
  },
  PRODUCTS: {
    translation: "Productos",
    icon: faClipboardList,
    color: "#5BB218",
    redirectURL: "/protected/products",
  },
};

export const markCommunicationAsRead: AsyncAction<
  MarkCommunicationAsReadVariables,
  boolean
> = async ({ state, effects }, variables) => {
  try {
    const { markCommunicationAsRead } =
      await effects.api.mutations.markCommunicationAsRead(variables);

    state.communicationAlerts.forEach(function (alert, i) {
      if (alert.id === variables.alertId) {
        alert.seen = true;
      }
    });

    /* Define the unseen alerts depending on the current page of the query*/
    let totalUnseen = setTotalUnseen(state);
    if (
      totalUnseen.length === 0 &&
      state.currentPageCommAlert !== state.totalCommAlertPages
    ) {
      const { communicationAlerts } =
        await effects.api.queries.getCommunicationAlerts({
          page: state.currentPageCommAlert + 1,
          perPage: DEFAUL_PER_PAGE,
        });
      setCommunicationAlerts(state, communicationAlerts, assignedConfig, false);
      setUnseenCommunicationAlerts(state);
      setPaginationInfo(state, communicationAlerts);
    } else {
      setUnseenCommunicationAlerts(state);
    }

    return markCommunicationAsRead;
  } catch (error) {
    console.error("(markCommunicationAsRead) -----> Mutation error: ", error);
    return false;
  }
};

export const fetchCommunications: AsyncAction<
  CommunicationAlertsVariables,
  void
> = async ({ state, effects }, variables) => {
  const { communicationAlerts } =
    await effects.api.queries.getCommunicationAlerts(variables);
  setCommunicationAlerts(
    state,
    communicationAlerts,
    assignedConfig,
    variables.page !== 1 ? false : true
  );
  setUnseenCommunicationAlerts(state);
  setPaginationInfo(state, communicationAlerts);
};

function setCommunicationAlerts(
  state: any,
  communicationAlerts: any,
  assignedConfig: any,
  init: boolean
) {
  const communicationsMap = communicationAlerts.results.map((alert: any) => {
    return {
      id: alert.id,
      createdAt: alert.createdAt,
      category: alert.category,
      title: alert.title,
      description: alert.description,
      seen: alert.seen,
      config: assignedConfig[alert.category],
    };
  });
  state.communicationAlerts = init
    ? communicationsMap
    : state.communicationAlerts.concat(...communicationsMap);
}

function setUnseenCommunicationAlerts(state: any) {
  state.unseenCommunicationAlerts = [
    ...state.communicationAlerts.filter((alert: any) => alert.seen === false),
  ];
}

function setPaginationInfo(state: any, communicationAlerts: any) {
  state.totalCommAlertPages = communicationAlerts.totalPages;
  state.currentPageCommAlert = communicationAlerts.currentPage;
}

function setTotalUnseen(state: any) {
  return [
    ...state.communicationAlerts.filter((alert: any) => alert.seen === false),
  ];
}
