import styled from "styled-components";
import { colors } from "../../../constants/colors";
type propsFile = { label: string };
const RootContent = styled.div<propsFile>`
  position: relative;
  display: inline-block;
  input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }
  &::before {
    position: absolute;
    content: "${(props) => props.label}";
    background: #2c9dff;
    color: ${colors.white};
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.499;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
`;
export default RootContent;
