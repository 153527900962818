export default {
  loginContainer: {
    alignSelf: "center",
  },
  card: {
    background: "#fff",
    borderRadius: 12,
    padding: 24,
    shadowColor: "#C0C1CC",
    marginBottom: 8,
    boxShadow: "0 1px 2px #C0C1CC",
  },
  authTitle: {
    color: "#fff",
    marginTop: -150,
    fontSize: 32,
  },
};
