/**
 * Effect wrapper arount localStorage.getItem
 */
const getItem = async (key: string) => {
  return Promise.resolve(localStorage.getItem(key));
};

/**
 * Effect wrapper arount localStorage.setItem
 */
const setItem = async (key: string, value: string) => {
  return Promise.resolve(localStorage.setItem(key, value));
};

/**
 * Effect wrapper arount localStorage.removeItem
 */
const removeItem = async (key: string) => {
  return Promise.resolve(localStorage.removeItem(key));
};

export const storage = { getItem, setItem, removeItem };
