import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { Provider } from "overmind-react";
import { overmind } from "./overmind/index";
import "./index.scss";
import App from "./App";

Sentry.init({
  dsn:
    "https://f541bb54ef474cad97eab4331e695db0@o193684.ingest.sentry.io/5415520",
  environment: process.env.REACT_APP_ENVIRONMENT_NAME || "dev",
  integrations: [new CaptureConsole({ levels: ["warn", "error"] })],
});

ReactDOM.render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
