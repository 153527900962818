import styled from "styled-components";
import { colors } from "../../../constants/colors";
export type PropsColorsTag = "blue" | "yellow" | "default";

interface StateRootTag {
  color?: PropsColorsTag;
}

const RootTag = styled.div<StateRootTag>`
  border-radius: 8px;
  padding: 0px 7px 0px 2px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.color === "blue"
      ? colors.pacific_10
      : props.color === "yellow"
      ? colors.cheese_30
      : "transparent"};
`;

export default RootTag;
