import React from "react";
import { Table, Skeleton } from "antd";
import { ColumnProps } from "antd/lib/table";
import styles from "../../../pages/products/products.style";

function makeData(n: number) {
  const dataSource = [];
  for (var i = 0; i < n; ++i) {
    dataSource.push({
      key: i.toString(),
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
      column6: "",
      column7: "",
      column8: "",
    });
  }
  return dataSource;
}
export const TableProductSkeleton = () => {
  const dataSource = makeData(10);
  const columns: ColumnProps<any>[] = [
    { key: "key", title: "", dataIndex: "key" },
    { key: "column1", title: "Imagen", dataIndex: "column1" },
    { key: "column2", title: "Nombre", dataIndex: "column2" },
    { key: "column3", title: "Calificacion", dataIndex: "column3" },
    { key: "column4", title: "En Campaña", dataIndex: "column4" },
    { key: "column5", title: "Precio Base con IVA", dataIndex: "column5" },
    { key: "column6", title: "Ganancia sin IVA", dataIndex: "column6" },
    { key: "column7", title: "Inventario", dataIndex: "column7" },
    { key: "column8", title: "Estado", dataIndex: "column8" },
  ];
  return (
    <div>
      <Table
        className="table-products"
        bordered
        dataSource={dataSource}
        columns={columns.map((column) => {
          return {
            ...column,
            render: function renderPlaceholder() {
              if (column.key === "key")
                return (
                  <Skeleton
                    key={column.key}
                    title={false}
                    active
                    avatar={{
                      size: 20,
                      shape: "square",
                    }}
                    paragraph={false}
                  />
                );
              if (column.key === "column1")
                return (
                  <Skeleton
                    key={column.key}
                    title={false}
                    active
                    avatar={{
                      size: 100,
                      shape: "square",
                    }}
                    paragraph={false}
                  />
                );
              return (
                <Skeleton key={column.key} title active paragraph={false} />
              );
            },
          };
        })}
        pagination={false}
      />
    </div>
  );
};

export const CardProductSkeleton = () => {
  return (
    <div
      style={{ ...styles.card, backgroundColor: "white", textAlign: "center" }}
    >
      <Skeleton
        avatar={{
          shape: "square",
          size: 232,
          style: { marginTop: 40, marginBottom: 24 },
        }}
        title={false}
        active
        paragraph={false}
      />
      <Skeleton
        active
        title={false}
        paragraph={{
          rows: 2,
          width: 450,
          style: { display: "inline-block", justifyContent: "center" },
        }}
      />
      <Skeleton
        active
        paragraph={false}
        title={{
          width: 200,
          style: {
            display: "inline-block",
            justifyContent: "center",
            height: 40,
            marginBottom: 40,
          },
        }}
      />
    </div>
  );
};
