/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import { Route } from "react-router-dom";
import {useHistory} from "react-router";


const AuthRoute = ({ children, authType, ...rest }) => {

  const authToken = window.localStorage.getItem('auth_token');
  const canRender =
    (authType === "authorized" && authToken) ||
    (authType === "unauthorized");

  const history = useHistory();
  useEffect(() => {
    if (!canRender) {
      history.push('/auth');
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        canRender ? (
          children
        ) : <></>
      }
    />
  );
};

export default AuthRoute;
