import React from "react";
import { Container, Paragraph } from "../../components/elements";
import SVGLibrary from "../../constants/images";
import {
  Content,
  DivContentDescription,
  DivContentTitle,
  GiftLeft,
  GiftRight,
  Header,
  IconElenas,
  ImageNotFoundPage,
} from "./components";
import styles from "./styles";
import NotFoundImage from "../../assets/gifs/page-not-found-404-two.gif";
import { useOvermind } from "../../overmind";
import { Redirect } from "react-router";

const NotFoundPage = () => {
  const { state } = useOvermind();
  const {
    flags: {
      all: { mkt_maintenance_mode },
    },
  } = state;

  /* Checking if the value of the variant is undefined. If it is, it will return the value of "off". If
  it is not, it will return the value of the variant. */
  const valueVariant =
    typeof mkt_maintenance_mode.variant === "undefined"
      ? "off"
      : mkt_maintenance_mode.variant;

  /* Checking if the maintenance mode is on or off. */
  const mktMaintenanceMode = valueVariant === "off";

  return !mktMaintenanceMode ? (
    <Redirect path="/protected" to="/protected/maintenance" exact />
  ) : (
    <Content>
      <GiftLeft src={SVGLibrary({ name: "GiftLeft" })} alt="Regalos" />
      <GiftRight src={SVGLibrary({ name: "GiftRight" })} alt="Regalos" />
      {!mktMaintenanceMode && <Header />}
      <Container.Row id="main-maintenance" style={styles.content}>
        <Container.Column>
          <Container.Box style={styles.contentBox}>
            <DivContentTitle>
              <IconElenas
                src={SVGLibrary({ name: "FlameElenas" })}
                alt="Logo Elenas"
              />
              <Paragraph variant="h1" style={styles.textTitle1}>
                market
              </Paragraph>
              <Paragraph variant="text" style={styles.textTitle2}>
                place
              </Paragraph>
            </DivContentTitle>
            <ImageNotFoundPage src={NotFoundImage} alt="Página no encontrada" />
            <DivContentDescription>
              <Paragraph variant="text" style={styles.descriptionText1}>
                Ups! 404 No se encuentra la página!
              </Paragraph>
            </DivContentDescription>
          </Container.Box>
        </Container.Column>
      </Container.Row>
    </Content>
  );
};

export default NotFoundPage;
