import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants/colors";
const RootDivider = styled("div")`
  height: 1px;
  background: ${colors.stone_10};
`;
export const Divider = ({...props}:{style?:React.CSSProperties}) => {
  return <RootDivider {...props} />;
};
