import React from "react";
import { colorsText } from "./@types";
import { RootTitle, RootText, RootCaption } from "./styles";
type typesParagraph = {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "caption" | "text";
  className?: string;
  color?: colorsText;
  style?: React.CSSProperties;
  strong?: boolean;
  underlined?: boolean;
  cursorMouse?: boolean;
  onClick?: any;
};
export const Paragraph: React.FC<typesParagraph> = ({
  children,
  variant,
  ...rest
}) => {
  const Text = {
    h1: (
      <RootTitle variant="h1" {...rest}>
        {children}
      </RootTitle>
    ),
    h2: (
      <RootTitle variant="h2" {...rest}>
        {children}
      </RootTitle>
    ),
    h3: (
      <RootTitle variant="h3" {...rest}>
        {children}
      </RootTitle>
    ),
    h4: (
      <RootTitle variant="h4" {...rest}>
        {children}
      </RootTitle>
    ),
    h5: (
      <RootTitle variant="h5" {...rest}>
        {children}
      </RootTitle>
    ),
    h6: (
      <RootTitle variant="h6" {...rest}>
        {children}
      </RootTitle>
    ),
    caption: <RootCaption {...rest}>{children}</RootCaption>,
    text: <RootText {...rest}>{children}</RootText>,
  };
  return Text[variant];
};
