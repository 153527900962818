import styled from "styled-components";
import { colors } from "../../../constants/colors";

export const Content = styled.div`
  position: relative;
`;
export const Header = styled.div`
  width: 100%;
  height: 10px;
  background: ${colors.eva_50};
  border-bottom-right-radius: 29px;
  border-bottom-left-radius: 29px;
`;
export const IconElenas = styled.img`
  width: 33px;
  height: 62px;
`;
export const DivContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;
export const ImageNotFoundPage = styled.img`
  width: 330px;
  height: 262px;
  @media only screen and (max-width: 390px) {
    width: 100%;
    padding: 10px;
  }
`;
export const DivContentDescription = styled.div`
  padding: 20px 0px;
`;
export const GiftLeft = styled.img`
  position: absolute;
  left: 0;
  top: 90px;
  z-index: 1;
  @media only screen and (max-width: 935px) {
    display: none;
  }
`;
export const GiftRight = styled.img`
  position: absolute;
  right: 0;
  z-index: 1;
  @media only screen and (max-width: 935px) {
    display: none;
  }
`;
