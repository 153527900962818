import styled from "styled-components";
import { colors } from "../../../../constants/colors";

interface PropsRootBar {
  lock: boolean;
  percentage: number;
}

export const RootBar = styled.div<PropsRootBar>`
  height: 19px;
  background: ${(props) => (props.lock ? colors.stone_60 : colors.eva_40)};
  width: ${(props) => `${props.percentage}%`};
  border-radius: 20px;
  text-align: center;
`;
