import React from "react";
import styled from "styled-components";

const RootCard = styled("div")`
  background-color: #fff;
  position: relative;
  display: block;
  font-family: "Raleway", sans-serif;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 24px;
  box-sizing: border-box;
  border: 1px solid #D8DBDC;
`;

const RootHeader = styled("div")` 
  padding: 10px 0px 10px; 
`;

const RootContent = styled("div")` 
  width: 100%; 
  padding: 0px 25px;
  @media only screen and (max-width: 768px) {
    padding: 0px 5px;
  }
`;
 

const Box: React.FC<{id ?: string, style?: React.CSSProperties}> = ({ children, ...rest }) => {
  return <RootCard {...rest}>{children}</RootCard>;
};

const Header: React.FC<{style?: React.CSSProperties}> = ({ children, ...rest }) => {
  return <RootHeader {...rest}>{children}</RootHeader>;
};
 
const Content: React.FC<{id ?: string, style?: React.CSSProperties}> = ({ children, ...rest }) => {
  return <RootContent {...rest}>{children}</RootContent>;
};

export const Card = {
  Box, 
  Header,
  Content,
};
