import React from "react";
import styled from "styled-components";
import { Spin } from "antd";


const RootContentPreloader = styled("div")`
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: block;
`;

const RootPreloader = styled("div")`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Preloader = ({tip = "Cargando Página"}:{tip?:string}) => {
  return (
    <RootContentPreloader>
      <RootPreloader>
        <Spin tip={tip} size="large" />
      </RootPreloader>
    </RootContentPreloader>
  );
};
