import { Action, AsyncAction } from "overmind";
import { BulkDeleteVariables } from "../effects/api/graphql-types/BulkDelete";
import { BulkUpdateInventoryVariables } from "../effects/api/graphql-types/BulkUpdateInventory";
import { BulkUpdateVisibilityVariables } from "../effects/api/graphql-types/BulkUpdateVisibility";
import {
  CreateBrandVariables,
  CreateBrand_createBrand,
} from "../effects/api/graphql-types/CreateBrand";
import {
  CreateProductVariables,
  CreateProduct_createProduct,
} from "../effects/api/graphql-types/CreateProduct";
import {
  DeleteProductImageVariables,
  DeleteProductImage_deleteProductImage,
} from "../effects/api/graphql-types/DeleteProductImage";
import {
  HideProductAlertVariables,
  HideProductAlert_hideProductAlert,
} from "../effects/api/graphql-types/HideProductAlert";
import { ProductsVariables } from "../effects/api/graphql-types/Products";
import {
  ToggleProductVisibilityVariables,
  ToggleProductVisibility_toggleProductVisibility,
} from "../effects/api/graphql-types/ToggleProductVisibility";
import {
  UpdateBrandVariables,
  UpdateBrand_updateBrand,
} from "../effects/api/graphql-types/UpdateBrand";
import {
  UpdateProductVariables,
  UpdateProduct_updateProduct,
} from "../effects/api/graphql-types/UpdateProduct";
import {
  UploadProductImageVariables,
  UploadProductImage_uploadProductImage,
} from "../effects/api/graphql-types/UploadProductImage";
import { ProductInput } from "../graphql-types/graphql-global-types";

export const fetchBrands: AsyncAction<void, void> = async (
  { actions, state, effects },
  variables
) => {
  try {
    const { brands } = await effects.api.queries.getBrands();
    // actions.fetchVendorInfo();
    state.brands = brands;
  } catch (error) {
    console.error("(FetchBrands) -----> Error: ", error);
  }
};

export const fetchCategories: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { categories } = await effects.api.queries.getCategories();
    state.categories = categories;
  } catch (error) {
    console.error("(FetchCategories) -----> Error: ", error);
  }
};

export const fetchProducts: AsyncAction<ProductsVariables, void> = async (
  { state, actions, effects },
  variables
) => {
  try {
    const { getForce } = variables;
    if (
      !!Object.keys(state.products).length &&
      variables.name === state.searchInput &&
      variables.sku === state.searchSku &&
      variables.page === state.productsPage &&
      variables.visible === state.productVisibleFilter &&
      variables.orderByInventory === state.productsOrderByInventory &&
      !getForce
    ) {
      return;
    }

    state.loadingProducts = true;
    const { products } = await effects.api.queries.getProducts(variables);
    state.loadingProducts = false;
    state.inventoryAlerts = [];

    state.products = {
      ...Object.assign(
        {},
        ...products.results.map((item) => ({ [item["id"]]: item }))
      ),
    };
    state.productOrder = products.results.map((product) => product.id);
    state.totalProductsCount = products.totalResults;
    state.productVisibleFilter = variables.visible;
    state.productsOrderByInventory = variables.orderByInventory;
    state.productsPage = products.currentPage;
    state.searchInput = variables.name;
    state.searchSku = variables.sku;
  } catch (error) {
    state.loadingProducts = false;
    console.error("(FetchProducts) -----> Error: ", error);
  }
};

export const fetchProductReviewsAvg: AsyncAction<void, void> = async ({
  state,
  effects,
}) => {
  if (state.productReviewsAvg) return;
  const productReviewsAvg = await effects.api.queries.fetchProductReviewsAvgQ();
  if (productReviewsAvg) {
    state.productReviewsAvg = productReviewsAvg.reviewAvgRating;
  }
};

export const fetchProductReviews: AsyncAction<
  {
    productId: number;
    page: number;
    perPage: number;
  },
  void
> = async ({ state, effects }, variables) => {
  if (
    state.productReviews[variables.productId] &&
    state.reviewsPage === variables.page
  )
    return;
  const { productReviews } =
    await effects.api.queries.fetchProductReviewsVendorsQ({
      ...variables,
    });
  if (productReviews) {
    const prevReviews = state.productReviews[variables.productId]
      ? state.productReviews[variables.productId].results
      : [];

    const productReview = {
      currentPage: productReviews.currentPage,
      totalPages: productReviews.totalPages,
      results: [
        ...prevReviews,
        ...productReviews.results.map((item) => {
          return {
            id: item.id,
            text: item.text,
            rating: item.rating,
            images: item.images,
            liked: item.liked,
            usefulCount: item.usefulCount,
            reviewer: item.reviewer,
          };
        }),
      ],
    };
    state.productReviews[variables.productId] = productReview;
    if (variables.page > state.reviewsPage) {
      state.reviewsPage = variables.page;
    }
  }
};

export const fetchAllProductReviews: AsyncAction<
  {
    page: number;
    perPage: number;
  },
  void
> = async ({ state, effects }, variables) => {
  if (state.allReviewsPage === variables.page && state.allProductReviews.length)
    return;
  const { productReviews } =
    await effects.api.queries.fetchProductReviewsVendorsQ({
      page: variables.page > 0 ? variables.page - 1 : variables.page,
      perPage: variables.perPage,
    });
  if (productReviews) {
    // const productReview = {
    //   currentPage: productReviews.currentPage,
    //   totalPages: productReviews.totalPages,
    //   results: productReviews.results.map((item) => {
    //     return {
    //       id: item.id,
    //       text: item.text,
    //       rating: item.rating,
    //       images: item.images,
    //       liked: item.liked,
    //       usefulCount: item.usefulCount,
    //       reviewer: item.reviewer,
    //     };
    //   }),
    // };
    state.allProductReviews = productReviews.results;
    state.allReviewsPage = variables.page;
    state.totalReviews =
      productReviews.totalPages * productReviews.resultsPerPage;
  }
};

export const fetchTopProductReviews: AsyncAction<
  {
    productId: number;
  },
  void
> = async ({ state, effects }) => {
  if (state.topProductReviews.length) return;
  const { productReviews } =
    await effects.api.queries.fetchProductReviewsVendorsQ({
      top: true,
      page: 0,
      perPage: 4,
    });
  if (productReviews) {
    state.topProductReviews = productReviews.results;
  }
};

export const fetchInventoryAlerts: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { inventoryAlerts } = await effects.api.queries.getInventoryAlerts();
    inventoryAlerts.forEach((item) => {
      state.products[item.product.id] = item.product;
    });
    state.inventoryAlerts = inventoryAlerts;
  } catch (error) {
    console.error("(FetchInventoryAlerts) -----> Error: ", error);
  }
};

export const fetchProductInventory: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { productsForInventory } =
      await effects.api.queries.getProductInventory();
    const titles = ["ID", "NOMBRE", "SKU", "INVENTARIO", "VISIBLE"];
    const data = productsForInventory.map((item) => [
      `=""${item.id}""`,
      item.name,
      `=""${item.sku}""`,
      item.quantity,
      item.visible,
    ]);
    state.productsInventory = [titles, ...data];
  } catch (error) {
    console.error("(FetchProductInventory) -----> Error: ", error);
  }
};

export const clearProducts: Action<void, void> = ({ state }, variables) => {
  state.products = {};
};

export const createProduct: AsyncAction<
  CreateProductVariables,
  CreateProduct_createProduct
> = async ({ effects }, variables) => {
  try {
    const { createProduct } = await effects.api.mutations.createProduct(
      variables
    );
    return createProduct;
  } catch (error) {
    console.error("(CreateProduct) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const updateProduct: AsyncAction<
  UpdateProductVariables,
  UpdateProduct_updateProduct
> = async ({ effects }, variables) => {
  try {
    const { updateProduct } = await effects.api.mutations.updateProduct(
      variables
    );
    return updateProduct;
  } catch (error) {
    console.error("(UpdateProduct) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const toggleProductVisibility: AsyncAction<
  ToggleProductVisibilityVariables,
  ToggleProductVisibility_toggleProductVisibility
> = async ({ effects }, variables) => {
  try {
    const { toggleProductVisibility } =
      await effects.api.mutations.toggleProductVisibility(variables);
    return toggleProductVisibility;
  } catch (error) {
    console.error("(ToggleProductVisibility) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const hideProductAlert: AsyncAction<
  HideProductAlertVariables,
  HideProductAlert_hideProductAlert
> = async ({ effects }, variables) => {
  try {
    const { hideProductAlert } = await effects.api.mutations.hideProductAlert(
      variables
    );
    return hideProductAlert;
  } catch (error) {
    console.error("(HideProductAlert) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const bulkUpdateVisibility: AsyncAction<
  BulkUpdateVisibilityVariables,
  boolean
> = async ({ effects }, variables) => {
  try {
    const { bulkUpdateVisibility } =
      await effects.api.mutations.bulkUpdateVisibility(variables);
    return bulkUpdateVisibility;
  } catch (error) {
    console.error("(BulkUpdateVisibility) -----> Mutation error: ", error);
    return false;
  }
};

export const bulkUpdateInventory: AsyncAction<
  BulkUpdateInventoryVariables,
  number
> = async ({ state, actions, effects }, variables) => {
  try {
    state.loadingProducts = true;
    const { bulkUpdateInventory } =
      await effects.api.mutations.bulkUpdateInventory(variables);
    state.loadingProducts = false;
    actions.fetchProducts({});
    return bulkUpdateInventory;
  } catch (error) {
    console.error("(BulkUpdateInventory) -----> Mutation error: ", error);
    return -1;
  }
};

export const bulkDelete: AsyncAction<BulkDeleteVariables, boolean> = async (
  { effects },
  variables
) => {
  try {
    const { bulkDelete } = await effects.api.mutations.bulkDelete(variables);
    return bulkDelete;
  } catch (error) {
    console.error("(BulkDelete) -----> Mutation error: ", error);
    return false;
  }
};

export const uploadProductImage: AsyncAction<
  UploadProductImageVariables,
  UploadProductImage_uploadProductImage
> = async ({ effects }, variables) => {
  try {
    const { uploadProductImage } =
      await effects.api.mutations.uploadProductImage(variables);
    return uploadProductImage;
  } catch (error) {
    console.error("(UploadProductImage) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: `Ocurrió un error al subir la imagen "${variables.input.name}"`,
      errors: [],
    };
  }
};

export const deleteProductImage: AsyncAction<
  DeleteProductImageVariables,
  DeleteProductImage_deleteProductImage
> = async ({ effects }, variables) => {
  try {
    const { deleteProductImage } =
      await effects.api.mutations.deleteProductImage(variables);
    return deleteProductImage;
  } catch (error) {
    console.error("(DeleteProductImage) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: `Ocurrió un error al borrar la imagen`,
      errors: [],
    };
  }
};

export const createBrand: AsyncAction<
  CreateBrandVariables,
  CreateBrand_createBrand
> = async ({ actions, effects }, variables) => {
  try {
    const { createBrand } = await effects.api.mutations.createBrand(variables);
    actions.fetchBrands();
    return createBrand;
  } catch (error) {
    console.error("(CreateBrand) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: `Ocurrió un error al guardar la marca "${variables.input.brandName}"`,
      errors: [],
    };
  }
};

export const updateBrand: AsyncAction<
  UpdateBrandVariables,
  UpdateBrand_updateBrand
> = async ({ actions, effects }, variables) => {
  try {
    const { updateBrand } = await effects.api.mutations.updateBrand(variables);
    actions.fetchBrands();
    return updateBrand;
  } catch (error) {
    console.error("(UpdateBrand) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: `Ocurrió un error al guardar la marca "${variables.input.brandName}"`,
      errors: [],
    };
  }
};

export const addProductToCreateProduct: Action<ProductInput, void> = (
  { state },
  data
) => {
  state.createProduct = {
    ...state.createProduct,
    ...data,
  };
};

export const resetCreateProductState: Action<void, void> = ({ state }) => {
  state.createProduct = {
    productName: "",
    description: "",
    brandId: 0,
    sku: "",
    inventory: 0,
    basePrice: 0,
    categoryId: 0,
  };
};

export const getProductVariantValues: ({
  state,
  effects,
}: {
  state: any;
  effects: any;
}) => Promise<void> = async ({ state, effects }) => {
  const { productVariantValues } =
    await effects.api.queries.getProductVariantValues();
  state.productVariantValues = productVariantValues;
};
