import React, { lazy } from "react";
const Dashboard = lazy(() => import("../pages/dashboard"));
const PaymentsAndReports = lazy(
  () => import("../pages/payments/paymentsAndReports")
);
const ProductsPage = lazy(() => import("../pages/products/products"));
const OrderDetailPage = lazy(
  () => import("../pages/order-detail/order-detail")
);
const OrdersPage = lazy(() => import("../pages/orders/orders"));
const PageExperience = lazy(() => import("../pages/experience"));
const ReviewsPage = lazy(() => import("../pages/reviews/reviews"));
const DetailProductReviewPage = lazy(
  () => import("../pages/reviews/detail-product-review")
);
const Communications = lazy(
  () => import("../pages/communications/communications")
);
const ProductVariantDetailPage = lazy(
  () => import("../pages/product-variant/product-variant")
);
const ProfilePage = lazy(() => import("../pages/profile/profile"));
const NotFoundPage = lazy(() => import("../pages/not-found/not-found"));
const Error500Page = lazy(() => import("../pages/error500"));

type TypesRoutes = {
  name?: string;
  route?: string;
  component?: React.ReactNode;
  protected?: boolean;
  exact?: boolean;
  /* A flag that is used to show a maintenance page. */
  maintenance?: boolean;
};

export const routes: TypesRoutes[] = [
  {
    name: "Dashboard",
    route: "/protected/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  {
    name: "Payments And Reports",
    route: "/protected/payments",
    component: <PaymentsAndReports />,
    protected: true,
  },
  {
    name: "Products",
    route: "/protected/products",
    component: <ProductsPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Products",
    route: "/protected/products/:page",
    component: <ProductsPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Products",
    route: "/protected/products/variants/new",
    component: <ProductVariantDetailPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Products",
    route: "/protected/products/variants/:id",
    component: <ProductVariantDetailPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Orders Details",
    route: "/protected/orders/:id",
    component: <OrderDetailPage />,
    protected: true,
  },
  {
    name: "Orders",
    route: "/protected/orders",
    component: <OrdersPage />,
    protected: true,
  },
  {
    name: "Experience",
    route: "/protected/experience",
    component: <PageExperience />,
    protected: true,
  },
  {
    name: "Reviews",
    route: "/protected/reviews",
    component: <ReviewsPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Reviews Details",
    route: "/protected/products/detail/:id",
    component: <DetailProductReviewPage />,
    protected: true,
    exact: true,
  },
  {
    name: "Comunications",
    route: "/protected/communications",
    component: <Communications />,
    protected: true,
  },
  {
    name: "Profile",
    route: "/protected/profile",
    component: <ProfilePage />,
    protected: true,
  },
  /* This is a route that is used to show a maintenance page. */
  {
    name: "Maintenance",
    route: "/protected/maintenance",
    component: <Error500Page />,
    protected: true,
    maintenance: true,
  },
  {
    name: "Maintenance",
    route: "/protected/maintenance",
    component: <Error500Page />,
    protected: true,
  },
  {
    name: "No Found Page",
    route: "*",
    component: <NotFoundPage />,
  },
];