/* eslint-disable */
import config from '../config/config';
import { Http } from '../overmind-graphql/src';

const notRequiredAuth = ["refreshTokenM"];

let refreshTokenPromise: Promise<void> | null;

export const buildHttpOptions = ({
  state,
  actions,
}: {
  state: any;
  actions: any;
}): Http => {
  const httpOptions: Http = {
    endpoint: config.apiUrl,
  };

  const defaultHeaders = {
    Authorization: "",
  };

  const executeRefreshToken = async () => {
    try {
      await actions.refreshToken();
    } catch (error) {
      console.error("Refresh token invalid", error);
    } finally {
      refreshTokenPromise = null;
    }
  }

  httpOptions.onUnauthorizedResponse = async () => {
    if (!!refreshTokenPromise) {
      return refreshTokenPromise;
    }

    refreshTokenPromise = executeRefreshToken();
    return refreshTokenPromise;
  };

  httpOptions.headers = (operationName?: string) => {
    if (!state.authToken ||
      (operationName?.length && notRequiredAuth.includes(operationName))) {
      return { ...defaultHeaders };
    }
    return {
      Authorization: `Token ${state.authToken}`,
    };
  };

  return httpOptions;
};
