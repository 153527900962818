import React from "react";
import { Icon } from "antd";
import { ThemeType } from "antd/lib/icon";
import {
  FaCheckCircle as CheckIcon,
  FaFlag as FlagIcon,
  FaCrown as CrownIcon,
  FaPen as PencilIcon,
  FaCheck as CheckNormalIcon,
} from "react-icons/fa";
import { MdClose as CloseIcon } from "react-icons/md";
export type PropsNameIcons =
  | "HomeIcon"
  | "PencilIcon"
  | "InfoIcon"
  | "CheckIcon"
  | "FlagIcon"
  | "CrownIcon"
  | "CheckNormalIcon"
  | "CloseIcon";
type TypesIcons = {
  name?: PropsNameIcons;
  theme?: ThemeType;
  spin?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
export const IconLibrary = ({ name = "HomeIcon", ...props }: TypesIcons) => {
  const Icons = {
    HomeIcon: <Icon type="home" {...props} />,
    PencilIcon: <PencilIcon {...props} />,
    InfoIcon: <Icon type="info-circle" {...props} />,
    CheckIcon: <CheckIcon {...props} />,
    FlagIcon: <FlagIcon {...props} />,
    CrownIcon: <CrownIcon {...props} />,
    CheckNormalIcon: <CheckNormalIcon {...props} />,
    CloseIcon: <CloseIcon {...props} />,
  };
  return Icons[name] || <Icon type="home" {...props} />;
};
