import { createOvermind, IConfig } from "overmind";
import { createHook } from "overmind-react";
import * as authActions from "./actions/auth";
import * as communicationAlertsActions from "./actions/communicationAlerts";
import * as generalActions from "./actions/general";
import * as ordersActions from "./actions/orders";
import * as paymentsActions from "./actions/payments";
import * as productsActions from "./actions/products";
import * as profileActions from "./actions/profile";
import * as variantsActions from "./actions/variants";
import { api } from "./effects/api";
import { storage } from "./effects/storage";
import onInitialize from "./onInitialize";
import { state } from "./state";
import flags from "./effects/flags";

const actions = {
  ...generalActions,
  ...authActions,
  ...productsActions,
  ...ordersActions,
  ...profileActions,
  ...paymentsActions,
  ...communicationAlertsActions,
  ...variantsActions
};

export const overmindConfig = {
  onInitialize,
  state,
  actions,
  effects: {
    api,
    storage,
    flags,
  },
};


declare module "overmind" {
  // tslint:disable:interface-name
  interface Config extends IConfig<typeof overmindConfig> {}
}

export const overmind = createOvermind(overmindConfig, {
  devtools: process.env.REACT_APP_OVERMIND_DEV_TOOLS,
});

export const useOvermind = createHook<typeof overmindConfig>();
