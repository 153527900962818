import styled from "styled-components";
import { colors } from "../../../../constants/colors";

interface PropsDashedBorder {
  percentageFlag: number;
}

export const RootDashedBorder = styled.div<PropsDashedBorder>`
  position: absolute;
  height: 25px;
  height: 40px;
  z-index: 1;
  border: 1px dashed ${colors.eva_80};
  top: -5px;
  left: ${(props) => `${props.percentageFlag}%`};
`;

interface PropsRootFlag {
  percentageFlag: number;
}

export const RootFlag = styled.div<PropsRootFlag>`
  position: absolute;
  height: 20px;
  width: 80px;
  z-index: 1;
  background: ${colors.eva_80};
  right: ${(props) => `${props.percentageFlag - 0.3}%`};
  bottom: -30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 5px;
    border-color: transparent ${colors.eva_80} transparent ${colors.eva_80};
    left: -5px;
    top: 0;
    transform: rotateY(180deg);
  }
  p {
    font-size: 12px;
  }
`;
