import React, { useState } from 'react'
import { Typography } from "antd";

import styles from "./reset-password.style";
import FormEmail from './form-email';
import ChangePassword from './form-change-password';
import SwitchComponent from './switch-component';

const ResetPasswordPage = () => {

  // STATE
  const [email, setEmail] = useState("");
  const [activeComponent, setActiveComponent] = useState("sendEmail")

  // INTERNAL VARS
  const { Title } = Typography;

  return (
    <div>
      <Title style={styles.authTitle} level={3}>
        Restablecer contraseña
      </Title>
      <div style={styles.card}>
        <div style={styles.message} />
        <div style={styles.formContainer}>
          {activeComponent === "sendEmail" &&
            <p style={{ fontSize: 18 }}>
                Te enviaremos un correo a la cuenta de correo que tienes
                registrada con nosotros para restablecer tu contraseña
            </p>
          }
          {activeComponent === "sendCode" &&
            <p style={{ fontSize: 18 }}>
              {`Ingresa el codigo de verificación que fue enviado a la cuenta de correo
              ${email}`}
            </p>
          }

          <SwitchComponent active={activeComponent}>
            <FormEmail setActiveComponent={setActiveComponent}
              setEmail={setEmail}
              email={email}
              name="sendEmail" />
            <ChangePassword name="sendCode"
              email={email}
              setActiveComponent={setActiveComponent}/>
          </SwitchComponent>

        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage