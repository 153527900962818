import { gql, Query } from "../../../overmind-graphql/src";
import { BulkDelete, BulkDeleteVariables } from "./graphql-types/BulkDelete";
import {
  BulkUpdateInventory,
  BulkUpdateInventoryVariables,
} from "./graphql-types/BulkUpdateInventory";
import {
  BulkUpdateVisibility,
  BulkUpdateVisibilityVariables,
} from "./graphql-types/BulkUpdateVisibility";
import { CreateBrand, CreateBrandVariables } from "./graphql-types/CreateBrand";
import {
  CreateProduct,
  CreateProductVariables,
} from "./graphql-types/CreateProduct";
import {
  DeleteProductImage,
  DeleteProductImageVariables,
} from "./graphql-types/DeleteProductImage";
import {
  HideProductAlert,
  HideProductAlertVariables,
} from "./graphql-types/HideProductAlert";
import { Login, LoginVariables } from "./graphql-types/Login";
import { sendPasswordResetCode,
  sendPasswordResetCodeVariables
} from "./graphql-types/sendPasswordResetCode";
import {
  resetPassword,
  resetPasswordVariables
} from './graphql-types/resetPassword'
import {
  MarkCommunicationAsRead,
  MarkCommunicationAsReadVariables,
} from "./graphql-types/MarkCommunicationAsRead";
import { SignUp, SignUpVariables } from "./graphql-types/SignUp";
import {
  ToggleProductVisibility,
  ToggleProductVisibilityVariables,
} from "./graphql-types/ToggleProductVisibility";
import { UpdateBrand, UpdateBrandVariables } from "./graphql-types/UpdateBrand";
import { UpdateOrderToDispatched, UpdateOrderToDispatchedVariables } from "./graphql-types/UpdateOrderToDispatched";
import { UpdateOrderToProcess, UpdateOrderToProcessVariables } from "./graphql-types/UpdateOrderToProcess";
import {
  UpdateProduct,
  UpdateProductVariables,
} from "./graphql-types/UpdateProduct";
import {
  UpdateVendorAccountingInformation,
  UpdateVendorAccountingInformationVariables,
} from "./graphql-types/UpdateVendorAccountingInformation";
import {
  UpdateVendorBasicInformation,
  UpdateVendorBasicInformationVariables,
} from "./graphql-types/UpdateVendorBasicInformation";
import {
  UploadProductImage,
  UploadProductImageVariables,
} from "./graphql-types/UploadProductImage";
import {
  UploadVendorDocument,
  UploadVendorDocumentVariables,
} from "./graphql-types/UploadVendorDocument";
import { RefreshToken, RefreshTokenVariables } from "./graphql-types/RefreshToken";

export const login: Query<Login, LoginVariables> = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ... on AuthInfo {
        token
        refresh_token
        user {
          id
          firstName
          lastName
          cellphone
          email
          cedula
        }
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const sendPasswordResetCodeM: Query<
  sendPasswordResetCode,
  sendPasswordResetCodeVariables
> = gql`
  mutation SendPasswordResetCodeEmail($email: String!){
    sendPasswordResetCodeEmail(email: $email)
  }
` as any;

export const resetPasswordM: Query<
  resetPassword,
  resetPasswordVariables
> = gql`
  mutation resetPasswordByEmail(
    $email: String!
    $code: String!
    $newPassword: String!
  ) {
    resetPasswordByEmail(
      email: $email
      code: $code
      newPassword: $newPassword
    ) {
      success
      authInfo {
        token
        refresh_token
      }
      error
    }
  }
` as any;

export const signUp: Query<SignUp, SignUpVariables> = gql`
  mutation SignUp($email: String!, $cellphone: String!, $password: String!) {
    signUp(email: $email, cellphone: $cellphone, password: $password) {
      ... on AuthInfo {
        token
        refresh_token
        user {
          id
          firstName
          lastName
          cellphone
          email
          cedula
        }
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const refreshTokenM: Query<RefreshToken, RefreshTokenVariables> = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      refresh_token
    }
  }
` as any;
 
export const updateOrderToProcess: Query<UpdateOrderToProcess, UpdateOrderToProcessVariables> = gql`
  mutation UpdateOrderToProcess($id: Int!) {
    updateOrderToProcess(id: $id) {
      __typename
      ... on Order {
        currentNewStatus {
          status
        }
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const updateOrderToDispatched: Query<UpdateOrderToDispatched, UpdateOrderToDispatchedVariables> = gql`
  mutation UpdateOrderToDispatched($id: Int!) {
    updateOrderToDispatched(id: $id) {
      __typename
      ... on Order {
        currentNewStatus {
          status
        }
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;


export const createProduct: Query<CreateProduct, CreateProductVariables> = gql`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input) {
      __typename
      ... on Product {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const updateProduct: Query<UpdateProduct, UpdateProductVariables> = gql`
  mutation UpdateProduct($productId: Int!, $input: ProductInput!, $validIncrease: Boolean, $increaseInput: IncreaseProductReasonInput) {
    updateProduct(productId: $productId, input: $input, validIncrease: $validIncrease, increaseInput: $increaseInput) {
      __typename
      ... on Product {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const updateVendorBasicInformation: Query<
  UpdateVendorBasicInformation,
  UpdateVendorBasicInformationVariables
> = gql`
  mutation UpdateVendorBasicInformation($input: VendorBasicInput!) {
    updateVendorBasicInformation(input: $input) {
      __typename
      ... on Vendor {
        id
        logoImgUrl
        backgroundImgUrl
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const updateVendorAccountingInformation: Query<
  UpdateVendorAccountingInformation,
  UpdateVendorAccountingInformationVariables
> = gql`
  mutation UpdateVendorAccountingInformation($input: VendorAccountingInput!) {
    updateVendorAccountingInformation(input: $input) {
      __typename
      ... on Vendor {
        id
        logoImgUrl
        backgroundImgUrl
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const uploadVendorDocument: Query<
  UploadVendorDocument,
  UploadVendorDocumentVariables
> = gql`
  mutation UploadVendorDocument($input: VendorDocumentInput!) {
    uploadVendorDocument(input: $input) {
      __typename
      ... on Vendor {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const toggleProductVisibility: Query<
  ToggleProductVisibility,
  ToggleProductVisibilityVariables
> = gql`
  mutation ToggleProductVisibility($productId: Int!) {
    toggleProductVisibility(productId: $productId) {
      __typename
      ... on Product {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const hideProductAlert: Query<
  HideProductAlert,
  HideProductAlertVariables
> = gql`
  mutation HideProductAlert($alertId: Int!) {
    hideProductAlert(alertId: $alertId) {
      __typename
      ... on ProductAlert {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const bulkUpdateVisibility: Query<
  BulkUpdateVisibility,
  BulkUpdateVisibilityVariables
> = gql`
  mutation BulkUpdateVisibility($productIds: [Int!]!, $visibility: Boolean!) {
    bulkUpdateVisibility(productIds: $productIds, visibility: $visibility)
  }
` as any;

export const bulkUpdateInventory: Query<
  BulkUpdateInventory,
  BulkUpdateInventoryVariables
> = gql`
  mutation BulkUpdateInventory($inventory: [ProductInventory!]!) {
    bulkUpdateInventory(inventory: $inventory)
  }
` as any;

export const bulkDelete: Query<BulkDelete, BulkDeleteVariables> = gql`
  mutation BulkDelete($productIds: [Int!]!) {
    bulkDelete(productIds: $productIds)
  }
` as any;

export const uploadProductImage: Query<
  UploadProductImage,
  UploadProductImageVariables
> = gql`
  mutation UploadProductImage($input: ProductImageInput!) {
    uploadProductImage(input: $input) {
      __typename
      ... on Product {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const deleteProductImage: Query<
  DeleteProductImage,
  DeleteProductImageVariables
> = gql`
  mutation DeleteProductImage($imageId: Int!) {
    deleteProductImage(imageId: $imageId) {
      __typename
      ... on Product {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const createBrand: Query<CreateBrand, CreateBrandVariables> = gql`
  mutation CreateBrand($input: BrandInput!) {
    createBrand(input: $input) {
      __typename
      ... on Brand {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const updateBrand: Query<UpdateBrand, UpdateBrandVariables> = gql`
  mutation UpdateBrand($brandId: Int!, $input: BrandInput!) {
    updateBrand(brandId: $brandId, input: $input) {
      __typename
      ... on Brand {
        id
      }
      ... on ValidationErrors {
        message
        errors {
          message
          field
        }
      }
    }
  }
` as any;

export const markCommunicationAsRead: Query<
  MarkCommunicationAsRead,
  MarkCommunicationAsReadVariables
> = gql`
  mutation MarkCommunicationAsRead($alertId: Int!) {
    markCommunicationAsRead(alertId: $alertId)
  }
` as any;
