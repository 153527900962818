import React from "react";
import { Modal as ModalAntd } from "antd";
import styled, { CSSProperties } from "styled-components";
import { Divider } from "..";
import { Paragraph } from "../paragraph";
import { colors } from "../../../constants/colors";
import { IconLibrary } from "../icons";

type TypesModal = {
  visible?: boolean;
  onOpen?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
const Box: React.FC<TypesModal> = ({ children, onClose, onOpen, ...rest }) => {
  return (
    <ModalAntd
      className="modal-medals"
      footer={null}
      {...rest}
      bodyStyle={{ padding: 0 }}
      onCancel={onClose}
      onOk={onOpen} 
      closeIcon={
        <IconLibrary name="CloseIcon" style={{color:colors.white, fontSize: 45}}/>
      } 
    >
      {children}
    </ModalAntd>
  );
};

type TypesModalHeader = {
  title?: string;
  stylesHeader?: React.CSSProperties
};

const RootHeader = styled("div")`
  padding: 10px 10px;
  background-color: ${colors.eva_50};
  color: ${colors.white};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

const Header: React.FC<TypesModalHeader> = ({ children, title, stylesHeader }) => {
  return (
    <>
      <RootHeader style={stylesHeader}>
        <Paragraph variant="h2" strong color="white">{title}</Paragraph>
        {children}
      </RootHeader>
      <Divider />
    </>
  );
};

const RootContent = styled("div")`
  padding: 10px 20px;
`;

const Content: React.FC<{style?:CSSProperties}> = ({ children, ...props }) => {
  return <RootContent {...props}>{children}</RootContent>;
};

const RootFooter = styled("div")`
  text-align: right;
  padding: 10px 10px;
`;

const Footer: React.FC<{style?:CSSProperties}> = ({ children , ...props }) => {
  return (
    <>
      <Divider />
      <RootFooter {...props}>{children}</RootFooter>
    </>
  );
};

export const Modal = { Box, Header, Footer, Content };
