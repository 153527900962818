import { Action, AsyncAction } from "overmind";
import { configurationsVariables } from "../effects/api/graphql-types/configurations";
import { gqlToDepartment, gqlToWarehouseAddress } from "../state";

export const fetchInitialData: AsyncAction<void, void> = async ({
  actions,
}) => {
  actions.resetState();
  await actions.fetchFlagsAll();
  await actions.initialFetchData();
};

export const fetchVendorInfo: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { vendorInfo } = await effects.api.queries.getVendorInfo();
    state.vendorInfo = gqlToWarehouseAddress(vendorInfo);
  } catch (error) {
    console.error("(FetchVendorInfo) -----> Error: ", error);
  }
};

export const setVendorImages: Action<{
  logoImgUrl: string | null;
  backgroundImgUrl: string | null;
}> = ({ state, effects }, variables) => {
  if (
    state.vendorInfo &&
    (variables.logoImgUrl || variables.backgroundImgUrl)
  ) {
    state.vendorInfo.logoImgUrl = variables.logoImgUrl
      ? variables.logoImgUrl.toString()
      : null;
    state.vendorInfo.backgroundImgUrl = variables.backgroundImgUrl
      ? variables.backgroundImgUrl?.toString()
      : "";
  }
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const fetchVendorMedals: AsyncAction<void, void> = async ({
  state,
  effects,
}) => {
  state.loadingVendorMedals = true;
  try {
    const data = await effects.api.queries.getVendorMedals();
    state.effectiveOrders = data.effectiveOrders
      ? data.effectiveOrders.total
      : 0;
    state.requirementDays = data.effectiveOrders
      ? data.effectiveOrders.requirementDays
      : 0;
    state.requirementOrders = data.effectiveOrders
      ? data.effectiveOrders.requirementOrders
      : 0;
    state.vendorMedals = data.vendorMedals
      ? data.vendorMedals.filter(notEmpty)
      : [];
  } catch (error) {
  } finally {
    state.loadingVendorMedals = false;
  }
};

export const fetchSalesSumary: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { salesSumary } = await effects.api.queries.getSalesSumary();
    state.salesSumary = salesSumary / 1000000;
  } catch (error) {
    console.error("(FetchSalesSumary) -----> Error: ", error);
  }
};

export const fetchDepartments: AsyncAction<void, void> = async ({
  state,
  effects,
}) => {
  try {
    const { states } = await effects.api.queries.getDepartments();
    state.departments = states.map((state) => gqlToDepartment(state));
  } catch (error) {
    console.error("(FetchDepartments) -----> Error: ", error);
  }
};

export const fetchTopClients: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { topClients } = await effects.api.queries.getTopClients();
    state.topClients = topClients;
  } catch (error) {
    console.error("(FetchTopClients) -----> Error: ", error);
  }
};

export const fetchCities: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { cities } = await effects.api.queries.getCities();
    state.cities = cities;
  } catch (error) {
    console.error("(FetchCities) -----> Error: ", error);
  }
};

export const fetchConfigurations: AsyncAction<
  configurationsVariables,
  void
> = async ({ state, effects }, variables) => {
  try {
    const { configurations } = await effects.api.queries.configurationsQ(
      variables
    );
    state.configurations = {
      ...Object.assign(
        {},
        ...configurations.map((item: any) => ({ [item["key"]]: item.value }))
      ),
    };
  } catch (error) {}
};

export const fetchBanks: AsyncAction<void, void> = async ({
  state,
  effects,
}) => {
  try {
    const { fetchBanks } = await effects.api.queries.getBanks();
    state.banks = fetchBanks ? fetchBanks : [];
  } catch (error) {
    console.error("(FetchBanks) -----> Error: ", error);
  }
};

export const fetchContracts: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { contract } = await effects.api.queries.getContracts();
    state.contracts = contract;
  } catch (error) {}
};

export const resetState: Action<void> = ({ state }) => {
  state.products = {};
  state.orders = {};
  state.guidesReports = [];
  state.searchInput = null;
  state.searchOrderId = null;
  state.productsPage = 1;
  state.ordersPage = 1;
  state.productsOrderByInventory = null;
  state.productVisibleFilter = null;
  state.paymentStatusFilter = null;
  state.orderStatusFilter = null;
};

/**
 * "fetchFlagsAll" is an async function that takes in an object with two properties: "state" and
 * "effects".
 * "state" is an object with two properties: "configurations" and "flags".
 * "configurations" is an object with one property: "COUNTRY_CODE".
 * "flags" is an object with three properties: "all", "constraints", and "loading".
 * "all" is an object with one property: "flagName".
 * "flagName" is an object with four properties: "flagKey", "flagName", "variant", and
 * "variantAttachment".
 * "constraints" is an object with three properties: "country", "semver_mayor", "semver_minor", and
 * "semver_patch".
 * "loading" is a boolean.
 * "effects" is an object with one property:
 * @param  - state.flags.all = { ...newFlags };
 */
export const fetchFlagsAll: AsyncAction<void> = async ({ state, effects }) => {
  const semVerConstraints = {
    semver_mayor: 1,
    semver_minor: 144,
    semver_patch: 1,
  };
  state.flags.maintenance = true;
  try {
    /* Fetching the flags from the server. */
    const response = await effects.flags.fetchAll(
      Object.values(state.flags.all),
      {
        ...state.flags.constraints,
        country: state.configurations.COUNTRY_CODE,
        ...semVerConstraints,
      }
    );
    let autoTrackedFlags: { [key: string]: string } = {};
    /* Creating a new object with the same keys as the original object, but with different values. */
    const newFlags = Object.values(state.flags.all).reduce((obj, item) => {
      const flagsProp = response?.data?.evaluationResults.find(
        (newFlag: { flagKey: string }) => newFlag.flagKey === item.flagKey
      );
      if (flagsProp?.evalContext?.flagTags?.includes("auto-tracked")) {
        autoTrackedFlags = {
          ...autoTrackedFlags,
          [item.flagName]: flagsProp?.variantKey,
        };
      }
      return Object.assign(obj, {
        [item.flagName]: {
          ...item,
          variant: flagsProp?.variantKey,
          variantAttachment: flagsProp?.variantAttachment,
          tags: flagsProp?.evalContext?.flagTags,
        },
      });
    }, state.flags.all);
    /* Creating a new object with the same keys as the original object, but with different values. */
    state.flags.all = { ...newFlags };
    state.flags.autoTrackedFlags = autoTrackedFlags;
    state.flags.constraints.semver_mayor =
      semVerConstraints?.semver_mayor ?? "";
    state.flags.constraints.semver_minor =
      semVerConstraints?.semver_minor ?? "";
    state.flags.constraints.semver_patch =
      semVerConstraints?.semver_patch ?? "";
    /*state.flags.constraints.friends_family = state.friendsFamily;*/
    state.flags.error = false;
  } catch (error: any) {
    console.error("(FLAGS) -----> Error: ", error);
    if (error.code === "ECONNABORTED") {
      state.flags.error = true;
    }
  } finally {
    state.flags.loading = false;
    state.flags.maintenance = false;
  }
};

/**
 * If the state.flags.all.mkt_maintenance_mode.variant is equal to 'on', then fetch data, else clear
 * state.
 * @param  - AsyncAction<void, void> = async ({
 */
export const initialFetchData: AsyncAction<void, void> = async ({
  state,
  actions,
}) => {
  const {
    flags: {
      all: { mkt_maintenance_mode },
    },
  } = state;
  const variant =
    typeof mkt_maintenance_mode.variant === "undefined"
      ? "off"
      : mkt_maintenance_mode.variant;

  if (variant === "off") {
    /* Calling the functions in the actions.ts file. */
    await actions.fetchVendorInfo();
    actions.fetchBrands();
    actions.fetchCategories();
    actions.fetchProductInventory();
    actions.fetchOrderDetails();
    actions.fetchCities();
    actions.fetchDepartments();
    actions.fetchBanks();
    actions.fetchInventoryAlerts();
    actions.fetchCommunications({ page: 1, perPage: 10 });
    // actions.fetchContracts();
  } else {
    /* Clearing the state. */
    state.vendorInfo = null;
    state.categories = [];
    state.productsInventory = [];
    state.orderDetails = [];
    state.cities = [];
    state.departments = [];
    state.banks = [];
    state.inventoryAlerts = [];
    state.communicationAlerts = [];
    state.unseenCommunicationAlerts = [];
    state.totalCommAlertPages = 0;
    state.currentPageCommAlert = 0;
    state.contracts = [];
    state.productsList = [];
    state.loadingProducts = false;
    state.brands = [];
  }
};
