import styled from "styled-components";
import { colors } from "../../../../constants/colors";

interface PropsRootContentBar {
  lock: boolean | null;
}
export const RootContentBar = styled.div<PropsRootContentBar>`
  border: 8px solid ${(props) => (props.lock ? colors.stone_10 : colors.eva_10)};
  border-radius: 17.5px;
  position: relative;
`;
