import React, { useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Menu, Dropdown, Icon, Drawer, Button, Badge } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faTruck,
  faBell,
  faUserCircle,
  faChevronDown,
  faUser,
  faSignOutAlt,
  faMoneyBill,
  faChartLine,
  faAward,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./nav.style";
import { useOvermind } from "../../overmind/index";

const Nav = (props) => {
  const history = useHistory();
  const {
    state,
    actions: { logout },
  } = useOvermind();
  const authToken = state.authToken;
  const vendorInfo = state.vendorInfo || {};
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  function redirectTo(url) {
    history.push(url);
  }

  const userMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => redirectTo("/protected/profile")}>
        <div
          style={styles.dropdownItem}
          onClick={() => setVisibleDrawer(false)}
        >
          <FontAwesomeIcon
            icon={faUser}
            className="item-link-menu-icon"
            style={{ marginRight: "3px" }}
          />{" "}
          Tu cuenta
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Link onClick={() => logout()} style={styles.dropdownItem} to="/">
          <FontAwesomeIcon
            icon={faSignOutAlt}
            className="item-link-menu-icon"
          />{" "}
          Cerrar sesión
        </Link>
      </Menu.Item>
    </Menu>
  );

  const mainMenu = (
    <Menu
      mode="horizontal"
      style={styles.menu}
      selectedKeys={[props.location.pathname]}
    >
      <Menu.Item
        key="/protected/products"
        onClick={() => redirectTo("/protected/products")}
      >
        <div className="item-link-menu link-nav">
          <FontAwesomeIcon icon={faBookOpen} className="item-link-menu-icon" />
          Productos
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/orders"
        onClick={() => redirectTo("/protected/orders")}
      >
        <div className="item-link-menu link-nav">
          <FontAwesomeIcon icon={faTruck} className="item-link-menu-icon" />
          Órdenes
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/payments"
        onClick={() => redirectTo("/protected/payments")}
      >
        <div className="item-link-menu link-nav">
          <FontAwesomeIcon icon={faMoneyBill} className="item-link-menu-icon" />
          Pagos
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/dashboard"
        onClick={() => redirectTo("/protected/dashboard")}
      >
        <div className="item-link-menu link-nav">
          <FontAwesomeIcon icon={faChartLine} className="item-link-menu-icon" />
          Dashboard
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/experience"
        onClick={() => redirectTo("/protected/experience")}
      >
        <div className="item-link-menu link-nav">
          <FontAwesomeIcon icon={faAward} className="item-link-menu-icon" />
          Experiencia
        </div>
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Menu style={styles.menu} selectedKeys={[props.location.pathname]}>
      <Menu.Item
        key="/protected/products"
        onClick={() => redirectTo("/protected/products")}
      >
        <div className="item-link-menu" onClick={() => setVisibleDrawer(false)}>
          <FontAwesomeIcon
            icon={faBookOpen}
            className="item-link-menu-icon mobile-menu"
          />
          Productos
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/orders"
        onClick={() => redirectTo("/protected/orders")}
      >
        <div className="item-link-menu" onClick={() => setVisibleDrawer(false)}>
          <FontAwesomeIcon
            icon={faTruck}
            className="item-link-menu-icon mobile-menu"
          />
          Ordenes
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/payments"
        onClick={() => redirectTo("/protected/payments")}
      >
        <div className="item-link-menu" onClick={() => setVisibleDrawer(false)}>
          <FontAwesomeIcon
            icon={faMoneyBill}
            className="item-link-menu-icon mobile-menu"
          />
          Pagos
        </div>
      </Menu.Item>
      <Menu.Item
        key="/protected/experience"
        onClick={() => redirectTo("/protected/experience")}
      >
        <div className="item-link-menu" onClick={() => setVisibleDrawer(false)}>
          <FontAwesomeIcon
            icon={faAward}
            className="item-link-menu-icon mobile-menu"
          />
          Experiencia
        </div>
      </Menu.Item>
    </Menu>
  );

  const loggedOutMenu = (
    <Menu theme="light" mode="horizontal" style={styles.menuRight}>
      <Menu.Item key="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Link to="/auth/signin" style={styles.loginButton}>
          Iniciar sesion
        </Link>
      </Menu.Item>
      <Menu.Item key="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Link to="/auth/signup" style={styles.signUpButton}>
          Crea tu cuenta
        </Link>
      </Menu.Item>
    </Menu>
  );

  const loggedOutMobileMenu = (
    <Menu theme="light">
      <Menu.Item key="1">
        <Link to="/auth/signin">Iniciar sesion</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/auth/signup">Crea tu cuenta</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="menuBar">
      <div className="menuCon">
        <div className="logo">
          <Link to="/">
            <img alt="logo" src={require("../../assets/images/logo.svg")} />
          </Link>
        </div>
        <div className="leftMenu">{authToken && mainMenu}</div>
        <div className="rightMenu">
          {authToken ? (
            <div style={styles.containerRightMenus}>
              <Link
                to="/protected/communications"
                className="ant-dropdown-link"
                style={styles.notifyIcon}
              >
                {state.unseenCommunicationAlerts.length !== 0 && (
                  <Badge color="red" style={styles.alertDot} />
                )}
                <FontAwesomeIcon icon={faBell} className="" size="lg" />
              </Link>
              <Dropdown overlay={userMenu} placement="bottomRight">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="ant-dropdown-link"
                  href="#"
                  style={styles.containerRightItemMenus}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="" size="lg" />
                  <span style={styles.brandName}>
                    {vendorInfo.mainBrand
                      ? vendorInfo.mainBrand.name
                      : "Tu marca"}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="icon-menu-dropdown"
                  />
                </a>
              </Dropdown>
            </div>
          ) : (
            loggedOutMenu
          )}
        </div>
        <Button className="barsMenu" onClick={() => setVisibleDrawer(true)}>
          <Icon className="barsBtn" type="menu-fold" />
        </Button>
        <Drawer
          title="Menú"
          placement="right"
          closable={false}
          onClose={() => setVisibleDrawer(false)}
          visible={visibleDrawer}
          onBreakpoint={(broken) => console.log("Breakpoint:", broken)}
        >
          {authToken && mobileMenu}
          {authToken && (
            <hr
              style={{
                borderBottomColor: "#e8e8e8",
                borderTopColor: "transparent",
              }}
            />
          )}
          {authToken && (
            <Menu>
              <Menu.Item>
                <strong style={styles.dropdownItem}>
                  {vendorInfo.name ? vendorInfo.name : "Tu marca"}
                </strong>
              </Menu.Item>
            </Menu>
          )}
          {authToken ? userMenu : loggedOutMobileMenu}
        </Drawer>
      </div>
    </nav>
  );
};

export default withRouter(Nav);
