export default {
  footer: {
    backgroundColor: "#f0f1ff",
    textAlign: "center",
    paddingTop: 56,
    paddingBottom: 64,
  },
  textTitleHelp: {
    color: "#5B2AD0",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "5px",
  },
  textSubTitleHelp: {
    color: "#403952",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: "25px",
    display: "block",
  },
  support: {
    height: "auto",
    width: "60px",
  },
  buttonOutlined: {
    margin: "5px",
    backgroundColor: "transparent",
    borderRadius: "24px",
    fontWeight: "700",
    fontSize: "12px",
  },
  buttonOutlinedBlue: {
    border: "1px solid #5B2AD0",
    color: "#5B2AD0",
  },
  buttonOutlinedBlueLight: {
    border: "1px solid #977DD1",
    color: "#977DD1",
  },
};
