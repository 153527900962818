import React from "react";  
import { Paragraph } from "../paragraph";
import { 
  RootBar, 
  RootContentBar, 
  RootContentProgress, 
  RootDashedBorder, 
  RootFlag 
} from "./styles";
 

type TypeProgress = {
  textBar: string
  lock : boolean
  max: number,
  min: number, 
  percentage:number,
  percentageFlag:number
  labelFlag:string
}
  
export const Progress = ({...props}:TypeProgress) => {
  const {
    max,
    lock,
    percentage, 
    textBar,
    percentageFlag,
    labelFlag 
  } = props 
  return (
    <RootContentProgress>
      <Paragraph 
        variant="text" 
        style={{
          position: "absolute",
          bottom: 0,
          top: 60
        }}
      >
        {max === 100 ? `${0} %` : `${0}`}
      </Paragraph>
       <Paragraph 
        variant="text" 
        style={{
          position: "absolute",
          bottom: 0,
          top: 60,
          right: 0
        }}
      >
        {max === 100 ? `${max} %` : <>{max}&nbsp;&nbsp;&nbsp;</>}
      </Paragraph>
      <RootContentBar lock={lock}>
         <RootDashedBorder  
          percentageFlag={percentageFlag} 
        /> 
        <RootFlag percentageFlag={ 100 - percentageFlag  } className="flag-bar"> 
          <Paragraph variant="text" color="white">{labelFlag}</Paragraph>
        </RootFlag> 
        <RootBar 
          lock={lock} 
          percentage={percentage} 
          title={textBar}
        >
          {percentage >= 5 ? <><Paragraph 
            variant="text" 
            color="white"
          >
            {textBar}
          </Paragraph></>:<></>}
          
        </RootBar> 
      </RootContentBar> 
    </RootContentProgress>
  )
}