import * as withAbsintheSocket from "@absinthe/socket";
import { GraphQLClient } from "graphql-request";

import gqlTag from "graphql-tag";
import { print } from "graphql/language/printer";
import { Socket as PhoenixSocket } from "phoenix";

export interface Query<Result extends any, Payload extends any = void> {
  (payload: Payload): Result;
}

type Variable = string | number | boolean | null;

interface NoPayloadSubscription<R> {
  (action: (result: R) => void): void;
  dispose(): void;
  disposeWhere(
    cb: (variables: { [variables: string]: Variable }) => boolean
  ): void;
}

interface PayloadSubscription<P, R> {
  (payload: P, action: (result: R) => void): void;
  dispose(): void;
  disposeWhere(
    cb: (variables: { [variables: string]: Variable }) => boolean
  ): void;
}

interface Subscription {
  variables: { [key: string]: Variable };
  dispose: () => void;
}

export type Http = {
  endpoint: string;
  headers?: (operationName?: string) => any;
  options?: any;
  onUnauthorizedResponse?: () => Promise<any>;
};

type Ws =
  | {
    endpoint: string;
    params?: () => { [key: string]: string | number | boolean };
  }
  | (() => PhoenixSocket | null);

type Queries = {
  queries?: {
    [key: string]: (payload: any) => any;
  };
  mutations?: {
    [key: string]: (payload: any) => any;
  };
  subscriptions?: {
    [key: string]: (payload: any) => any;
  };
};

export type Graphql<T extends Queries> = {
  initialize(http: Http, onResponse?: Function, ws?: Ws): void;
} & {
  queries: {
    [N in keyof T["queries"]]: T["queries"][N] extends (
      payload: infer P
    ) => infer R
    ? P extends void
    ? () => Promise<R>
    : (payload: P) => Promise<R>
    : never;
  };
  mutations: {
    [N in keyof T["mutations"]]: T["mutations"][N] extends (
      payload: infer P
    ) => infer R
    ? P extends void
    ? () => Promise<R>
    : (payload: P) => Promise<R>
    : never;
  };
  subscriptions: {
    [N in keyof T["subscriptions"]]: T["subscriptions"][N] extends (
      payload: infer P
    ) => infer R
    ? P extends void
    ? NoPayloadSubscription<R>
    : PayloadSubscription<P, R>
    : never;
  };
};

function createError(message: string) {
  throw new Error(`OVERMIND-GRAPHQL: ${message}`);
}

export const gql = (
  literals: string | readonly string[],
  ...placeholders: any[]
): Query<any, any> => gqlTag(literals, ...placeholders) as any;

const _clients: { [url: string]: GraphQLClient } = {};
const _subscriptions: {
  [query: string]: Subscription[];
} = {};

export const graphql: <T extends Queries>(queries: T) => Graphql<T> = (
  queries
) => {
  let _http: Http;
  let _ws: Ws;
  let _onResponse: Function;

  function getClient(operationName?: string): GraphQLClient | null {
    if (_http) {
      let headers = // eslint-disable-next-line
        typeof _http.headers === "function"
          ? _http.headers(operationName)
          : _http.options && _http.options.headers
            ? _http.options.headers
            : {};
      if (operationName) {
        headers["graphql-operation-name"] = operationName;
      }

      if (_clients[_http.endpoint]) {
        _clients[_http.endpoint].setHeaders(headers);
      } else {
        _clients[_http.endpoint] = new GraphQLClient(_http.endpoint, {
          ..._http.options,
          headers,
        });
      }

      return _clients[_http.endpoint];
    }

    return null;
  }

  let wsClient: PhoenixSocket | null = null;
  function getWsClient(): PhoenixSocket | null {
    if (_ws && !wsClient) {
      const socket =
        typeof _ws === "function"
          ? _ws()
          : new PhoenixSocket(_ws.endpoint, {
            params: _ws.params ? _ws.params() : undefined,
          });

      if (!socket) {
        throw createError(
          "You are trying to create a Socket for subscriptions, but there is no socket or socket information provided"
        );
      }
      /* @ts-ignore */
      wsClient = withAbsintheSocket.create(socket);
      return wsClient;
    }

    return wsClient;
  }

  const executeRawRequest = async (
    { operationName,
      query,
      variables,
      retries
    }:
      {
        operationName: string,
        query: any,
        variables: any,
        retries: number,
      }
  ) => {
    const client = getClient(operationName);

    if (!client) {
      throw createError(
        "You are running a query, though there is no HTTP endpoint configured"
      );
    }

    return client
      .rawRequest(print(query), variables)
      .then((r) => {
        _onResponse && _onResponse(r);
        return r.data;
      })
      .catch((e) => {
        const errorCode = e?.response?.errors[0]?.extensions?.code;
        const refreshTokenInvalid = e?.response?.status === 401 && retries === 0;

        if (errorCode || refreshTokenInvalid) {
          _onResponse && _onResponse({ errorCode: errorCode ?? e?.response?.status });
        }
        throw e;
      });
  };

  async function executeRequestWithRetries({
    operationName, query, variables, retries = 1 }: {
      operationName: string;
      query: any;
      variables: any;
      retries: number;
    }): Promise<any> {
    return new Promise((resolve, reject) => {
      return executeRawRequest({ operationName, query, variables, retries })
        .then(resolve)
        .catch((reason) => {
          if (reason.response?.status === 401 && retries > 0 && _http.onUnauthorizedResponse) {
            return _http
              .onUnauthorizedResponse()
              .then(
                executeRequestWithRetries.bind(
                  null,
                  {
                    operationName,
                    query,
                    variables,
                    retries: retries - 1
                  }
                )
              )
              .then(resolve)
              .catch(reject);
          } else {
            return reject(reason);
          }
        });
    });
  }

  const evaluatedQueries = {
    queries: Object.keys(queries.queries || {}).reduce((aggr, key) => {
      /* @ts-ignore */
      aggr[key] = (variables) => {
        const query = queries.queries![key] as any;
        return executeRequestWithRetries({
          operationName: key, query, variables, retries: 1
        });
      };
      return aggr;
    }, {}),
    mutations: Object.keys(queries.mutations || {}).reduce((aggr, key) => {
      /* @ts-ignore */
      aggr[key] = (variables) => {
        const query = queries.mutations![key] as any;
        return executeRequestWithRetries({
          operationName: key, query, variables, retries: 1
        });
      };
      return aggr;
    }, {}),
    subscriptions: Object.keys(queries.subscriptions || {}).reduce(
      (aggr, key) => {
        const query = queries.subscriptions![key] as any;
        const queryString = print(query);

        if (!_subscriptions[queryString]) {
          _subscriptions[queryString] = [];
        }

        /* @ts-ignore */
        function subscription(arg1, arg2) {
          const client = getWsClient();

          if (client) {
            const variables = arg2 ? arg1 : {};
            const action = arg2 || arg1;

            /* @ts-ignore */
            const notifier = withAbsintheSocket.send(client, {
              operation: queryString,
              variables,
            });

            /* @ts-ignore */
            const observer = withAbsintheSocket.observe(client, notifier, {
              /* @ts-ignore */
              onResult: ({ data }) => {
                action(data);
              },
            });

            _subscriptions[queryString].push({
              variables,
              dispose: () =>
                /* @ts-ignore */
                withAbsintheSocket.unobserve(client, notifier, observer),
            });
          } else {
            throw createError("There is no ws client available for this query");
          }
        }

        subscription.dispose = () => {
          _subscriptions[queryString].forEach((sub) => {
            try {
              sub.dispose();
            } catch (e) {
              // Ignore, it probably throws an error because we weren't subscribed in the first place
            }
          });
          _subscriptions[queryString].length = 0;
        };

        /* @ts-ignore */
        subscription.disposeWhere = (cb) => {
          _subscriptions[queryString] = _subscriptions[queryString].reduce<
            Subscription[]
          >((subAggr, sub) => {
            if (cb(sub.variables)) {
              try {
                sub.dispose();
              } catch (e) {
                // Ignore, it probably throws an error because we weren't subscribed in the first place
              }
              return subAggr;
            }
            return subAggr.concat(sub);
          }, []);
        };

        /* @ts-ignore */
        aggr[key] = subscription;

        return aggr;
      },
      {}
    ),
  };

  return {
    initialize(http: Http, onResponse?: Function, ws?: Ws) {
      _http = http;
      if (ws) {
        _ws = ws;
      }
      /* @ts-ignore */
      _onResponse = onResponse;
    },
    ...evaluatedQueries,
  } as any;
};
