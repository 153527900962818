import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Icon, notification } from "antd";
import { Link } from "react-router-dom";
import { useOvermind } from "../../overmind/index";

const ChangePassword = ({ form, email, setActiveComponent }) => {
  // STATE
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // OVERMIND
  const {
    state: { resetPasswordError },
    actions: { triggerPasswordReset, setResetPasswordError },
  } = useOvermind();

  const { getFieldDecorator } = form;

  const formSubmit = async (event) => {
    event.preventDefault()
    setLoading(true);
    await triggerPasswordReset({
      email: email,
      code: code,
      newPassword: password,
    });
    setLoading(false)
  }

  const onRequestNewCode = () => {
    setResetPasswordError({
      resetPasswordResponse: {
        success: false,
        authInfo: null,
        error: null,
      },
    });
    setActiveComponent("sendEmail")
  };

  useEffect(() => {
    if (resetPasswordError && resetPasswordError.error) {
      let errorMessage = "";
      switch (resetPasswordError.error) {
        case "PASSWORD_TOO_SHORT": {
          errorMessage = "La contraseña debe contener al menos 8 caractéres";
          break;
        }
        case "CODE_INVALID": {
          errorMessage =
            "El código de recuperación no es válido, solicita un nuevo código";
          break;
        }
        case "CODE_TIME_OUT": {
          errorMessage =
            "El código de recuperación ha expirado, solicita un nuevo código";
          break;
        }
        default: {
          errorMessage = "No se ha podido actualizar la contraseña";
          break;
        }
      }
      notification.error({
        message: errorMessage,
      });
    }
  }, [resetPasswordError]);


  return (
    <Form onSubmit={formSubmit} className="login-form" autocomplete="off">
      <Form.Item>
        {getFieldDecorator("code", {
          rules: [
            {
              required: true,
              message: "Por favor ingresa el código de verificación",
            },
          ],
        })(
          <Input
            type="text"
            placeholder="Código de verificación"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            required
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator("password", {
          rules: [
            {
              required: true,
              message: "Por favor ingresa una contraseña",
            },
            { min: 8, message: 'La contraseña debe contener al menos 8 caractéres.' },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "#A3A6CC" }} />}
            type="password"
            placeholder="Ingresa una contraseña"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("matchPassword", {
          rules: [
            {
              required: true,
              message: "Por favor repite la contraseña",
            }
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "#A3A6CC" }} />}
            type="password"
            placeholder="Repite la contraseña"
            onChange={(e) => {
              setConfirmPassword(e.target.value)
              if (password !== e.target.value) {
                setErrorMessage("La contraseña no coincide.");
              } else {
                setErrorMessage("");
              }
            }}
            required
          />
        )}
      </Form.Item>
      <div>{errorMessage}</div>
      <Form.Item style={{ marginBottom: 10 }}>
        <Button block type="primary"
          htmlType="submit"
          loading={loading}
          disabled={!code || password !== confirmPassword || !password}>
          Enviar codigo
        </Button>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Link to="#" onClick={onRequestNewCode}>Solicita un nuevo código</Link>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(ChangePassword)
