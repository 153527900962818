import { AsyncAction } from "overmind";
// import { fetchBanks, fetchBanks_fetchBanks } from "../effects/api/graphql-types/fetchBanks";
import {
  searchLocationByZipCodeVariables,
  searchLocationByZipCode_searchLocationByZipCode,
} from "../effects/api/graphql-types/searchLocationByZipCode";
import {
  UpdateVendorAccountingInformationVariables,
  UpdateVendorAccountingInformation_updateVendorAccountingInformation,
} from "../effects/api/graphql-types/UpdateVendorAccountingInformation";
import {
  UpdateVendorBasicInformationVariables,
  UpdateVendorBasicInformation_updateVendorBasicInformation,
} from "../effects/api/graphql-types/UpdateVendorBasicInformation";
import {
  UploadVendorDocumentVariables,
  UploadVendorDocument_uploadVendorDocument,
} from "../effects/api/graphql-types/UploadVendorDocument";

export const updateVendorBasicInformation: AsyncAction<
  UpdateVendorBasicInformationVariables,
  UpdateVendorBasicInformation_updateVendorBasicInformation
> = async ({ effects }, input) => {
  try {
    const { updateVendorBasicInformation } =
      await effects.api.mutations.updateVendorBasicInformation(input);
    return updateVendorBasicInformation;
  } catch (error) {
    console.error(
      "(updateVendorBasicInformation) -----> Mutation error: ",
      error
    );
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const updateVendorAccountingInformation: AsyncAction<
  UpdateVendorAccountingInformationVariables,
  UpdateVendorAccountingInformation_updateVendorAccountingInformation
> = async ({ actions, effects }, input) => {
  try {
    const { updateVendorAccountingInformation } =
      await effects.api.mutations.updateVendorAccountingInformation(input);
      await actions.fetchVendorInfo();
    return updateVendorAccountingInformation;
  } catch (error) {
    console.error(
      "(updateVendorAccountingInformation) -----> Mutation error: ",
      error
    );
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const uploadVendorDocument: AsyncAction<
  UploadVendorDocumentVariables,
  UploadVendorDocument_uploadVendorDocument
> = async ({ effects }, input) => {
  try {
    const { uploadVendorDocument } =
      await effects.api.mutations.uploadVendorDocument(input);
    return uploadVendorDocument;
  } catch (error) {
    console.error("(uploadVendorDocument) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const searchLocation: AsyncAction<
  searchLocationByZipCodeVariables,
  searchLocationByZipCode_searchLocationByZipCode
> = async ({ effects, state }, variables) => {
  try {
    const { searchLocationByZipCode } =
      await effects.api.queries.searchLocationByZipCodeQ(variables);
    if (searchLocationByZipCode.__typename === "LocationSuggestions") {
      state.zip_code = searchLocationByZipCode.zip_code;
      state.city = searchLocationByZipCode.city;
      state.cityId = searchLocationByZipCode.cityId;
      state.state = searchLocationByZipCode.state;
      state.stateId = searchLocationByZipCode.stateId;
      state.colonies = searchLocationByZipCode.colonies.sort() ?? [];
      state.country = searchLocationByZipCode.country;
      state.stateShortCode = searchLocationByZipCode.stateShortCode;
    }
    return searchLocationByZipCode;
  } catch (error) {
    console.error("(searchLocationByZipCode) -----> Query error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};
