import { derived } from "overmind";
import {
  SortDirection,
  PaymentStatus,
  VendorDocumentType,
  ProductInput,
} from "./graphql-types/graphql-global-types";
import { resetPassword_resetPassword } from "./effects/api/graphql-types/resetPassword";
/* Importing the StateFlags interface and the flags function from the flags/state module. */
import { StateFlags, flags } from "./flags/state";

export const state: State = {
  authToken: null,
  refreshToken: null,
  vendorInfo: null,
  effectiveOrders: 0,
  requirementOrders: 0,
  requirementDays: 0,
  loadingVendorMedals: false,
  vendorMedals: [],
  salesSumary: 0,
  topClients: [],
  loadingOrders: false,
  loadingCommunicationAlerts: false,
  orders: {},
  orderIds: [],
  ordersList: derived((state: State) =>
    state.orderIds.map((id) => state.orders[id])
  ),
  ordersPage: 1,
  totalOrdersCount: 0,
  lateOrderIds: [],
  lateOrderList: derived((state: State) =>
    state.lateOrderIds.map((id) => state.orders[id])
  ),
  totalLateOrders: 0,
  totalNewOrders: 0,
  totalDangerOrders: 0,
  OrderAlertsPage: 1,
  dangerOrderIds: [],
  dangerOrderList: derived((state: State) =>
    state.dangerOrderIds.map((id) => state.orders[id])
  ),
  newOrderIds: [],
  newOrderList: derived((state: State) =>
    state.newOrderIds.map((id) => state.orders[id])
  ),
  orderStatusFilter: null,
  paymentStatusFilter: null,
  productVisibleFilter: null,
  productsOrderByInventory: null,
  brands: [],
  categories: [],
  loadingProducts: false,
  products: {},
  productOrder: [],
  productsList: derived((state: State) =>
    Object.values(state.products).length
      ? state.productOrder.map((prodId) => state.products[prodId])
      : []
  ),
  productsPage: 1,
  totalProductsCount: 0,
  productsInventory: [],
  inventoryAlerts: [],
  orderDetails: [],
  searchInput: null,
  searchSku: null,
  searchOrderId: null,
  searchTrackingNumber: null,
  VendorDocumentInput: null,
  cities: [],
  departments: [],
  banks: [],
  payments: {
    ordersUpcomingPayment: null,
    loadingOrdersUpcomingPayment: false,
    ordersPendingEarnings: null,
    loadingOrdersPendingEarnings: false,
    ordersReceivedPayments: null,
    loadingOrdersReceivedPayments: false,
    ordersVendorPaid: null,
    loadingOrdersVendorPaid: false,
    loadingSendAccountingReports: false,
  },
  reviewsPage: 0,
  allReviewsPage: 0,
  totalReviews: 0,
  allProductReviews: [],
  productReviews: {},
  topProductReviews: [],
  productReviewsAvg: null,
  communicationAlerts: [],
  unseenCommunicationAlerts: [],
  totalCommAlertPages: 0,
  currentPageCommAlert: 0,
  configurations: {},
  zip_code: 0,
  state: "",
  stateId: 0,
  stateShortCode: "",
  city: "",
  cityId: 0,
  country: "",
  colonies: [],
  colonie: "",
  street: "",
  externalNumber: "",
  internalNumber: "",
  observations: "",
  reports: null,
  guidesReports: [],
  ordersReports: [],
  resetPassworSuccess: null,
  resetPasswordError: null,
  isProductSimple: true,
  activeStep: 0,
  productVariants: {
    detail: {
      variants: [],
    },
    images: {
      mainImage: {} as File,
      stockImages: [],
      ownImages: [],
    },
    imagesToDelete: [],
  },
  createProduct: {
    productName: "",
    description: "",
    brandId: 0,
    sku: "",
    inventory: 0,
    basePrice: 0,
    categoryId: 0,
    weight: null,
    height: null,
    length: null,
    width: null,
    guidesNumber: null,
  },
  contracts: [],
  productVariantValues: [],
  /* Creating a new object with the properties of flags and the value of flags. */
  flags: flags,
};

/**
 * TypesFlags is an object with a property called flags that is of type StateFlags
 * @property {StateFlags} flags - StateFlags;
 */
type TypesFlags = {
  flags: StateFlags;
};

type State = TypesFlags & {
  authToken: string | null;
  refreshToken: string | null;
  vendorInfo: Vendor | null;
  loadingVendorMedals: boolean;
  vendorMedals: vendorMedals[];
  effectiveOrders: number | null;
  requirementOrders: number | null;
  requirementDays: number | null;
  salesSumary: number;
  topClients: (TopClient | null)[];
  loadingOrders: boolean;
  loadingCommunicationAlerts: boolean;
  orders: { [id: number]: Order };
  orderIds: number[];
  ordersList: Order[];
  ordersPage: number;
  totalOrdersCount: number | null;
  lateOrderIds: number[];
  lateOrderList: any[];
  OrderAlertsPage: number | null;
  totalNewOrders: number | null;
  totalLateOrders: number | null;
  totalDangerOrders: number | null;
  dangerOrderIds: number[];
  dangerOrderList: any[];
  newOrderIds: number[];
  newOrderList: any[];
  orderStatusFilter: string | null | undefined;
  paymentStatusFilter: PaymentStatus | null | undefined;
  productVisibleFilter: boolean | null | undefined;
  productsOrderByInventory: SortDirection | null | undefined;
  brands: Brand[];
  categories: Category[];
  loadingProducts: boolean;
  products: { [id: number]: Product };
  productsList: Product[];
  allProductReviews: ProductReview[];
  productReviewsAvg: number | null;
  productReviews: {
    [productId: number]: {
      currentPage: number;
      totalPages: number;
      results: ProductReview[];
    };
  };
  topProductReviews: ProductReview[];
  productOrder: number[];
  productsPage: number;
  reviewsPage: number;
  allReviewsPage: number;
  totalReviews: number;
  totalProductsCount: number | null;
  productsInventory: any[];
  inventoryAlerts: NotificationAlert[];
  orderDetails: any[];
  searchInput?: string | null;
  searchSku?: string | null;
  searchOrderId?: number | null;
  searchTrackingNumber?: string | null;
  VendorDocumentInput: null;
  cities: City[];
  departments: Department[];
  banks: Bank[];
  communicationAlerts: any[];
  unseenCommunicationAlerts: any[];
  totalCommAlertPages: number;
  currentPageCommAlert: number;
  payments: {
    ordersUpcomingPayment: PaymentData | null;
    loadingOrdersUpcomingPayment: boolean;
    ordersPendingEarnings: PaymentData | null;
    loadingOrdersPendingEarnings: boolean;
    ordersReceivedPayments: PaymentPagination | null;
    loadingOrdersReceivedPayments: boolean;
    ordersVendorPaid: PaidOrdersPagination | null;
    loadingOrdersVendorPaid: boolean;
    loadingSendAccountingReports: boolean;
  };
  configurations: { [key: string]: string };
  zip_code: number | null;
  state: string | null;
  stateId: number | null;
  stateShortCode: String | null;
  city: string | null;
  cityId: number | null;
  country: string | null;
  colonies: string[] | null;
  colonie: string | null;
  street: string | null;
  externalNumber: string | null;
  internalNumber: string | null;
  observations: string | null;
  guidesReports: VendorReportList[] | null;
  ordersReports: VendorReportList[] | null;
  reports: VendorReport[] | null;
  resetPassworSuccess: resetPassword_resetPassword | null;
  resetPasswordError: resetPassword_resetPassword | null;
  isProductSimple: boolean;
  activeStep: number;
  // topClients: (TopClient | null)[];
  productVariants: {
    detail: DetailProduct | null;
    images: UploadImages;
    imagesToDelete: File[];
  };
  createProduct: ProductInput;
  contracts: Contract[];
  productVariantValues: ProductVariantValues[];
};

type Contract = {
  id: number;
  name: string;
  contract_file_url: string;
};

type DetailProduct = {
  variants?: VariantInput[];
};

type UploadImages = {
  mainImage: File;
  stockImages: File[];
  ownImages: File[];
};

type VariantInput = {
  id: string;
  tags: Tag[];
  type: ProductVariantValues;
};

type Tag = {
  id: number;
  name: string;
  statusTag?: string;
};

type PaymentData = {
  total: number;
  data: OrderPagination;
};

type OrderPagination = {
  currentPage: number;
  totalPages: number;
  totalResults: number | null;
  resultsPerPage: number;
  results: OrderToPayments[];
};

type PaymentPagination = {
  currentPage: number;
  totalPages: number;
  totalResults: number | null;
  resultsPerPage: number;
  results: VendorPaymentData[];
};

type VendorPaymentData = {
  id: number;
  invoiceNumber: number;
  accountingCutDate: string;
  paymentDate: string;
  totalPaid: number;
};

type PaidOrdersPagination = {
  currentPage: number;
  totalPages: number;
  totalResults: number | null;
  resultsPerPage: number;
  results: VendorPaidOrders[];
};

type VendorPaidOrders = {
  id: number;
  paymentDate: string;
  totalPaid: number;
  date: string;
  invoiceNumber: number;
  product: string;
};

type NotificationAlert = {
  id: number;
  createdAt: string;
  alertType: string;
  message: string | null;
  product: Product;
};

type ElenasCommissionRule = {
  ruleName: string;
  description: string;
  elenasCommission: number;
  priority: number;
  category: Category;
  upperAmountLimit: number;
  lowerAmountLimit: number;
};

type CommissionRuleVariable = {
  elenasCommission: number;
  upperAmountLimit: number;
  lowerAmountLimit: number;
};

export type Vendor = {
  id: number;
  businessName: string | null;
  personType: string | null;
  taxRegime: string | null;
  documentId: string | null;
  retentionAgent: boolean | null;
  fiscalCity: string | null;
  accountingsMail: string | null;
  commercialsMail: string | null;
  rawDocumentId: string | null;
  rawRut: string | null;
  rawBankCertificate: string | null;
  rawChamberOfCommerce: string | null;
  taxable: boolean | null;
  regimeSetted: boolean;
  productAlertsEnable: boolean | null;
  website: string | null;
  user: User;
  mainBrand: Brand;
  warehouseAddress: string | null;
  warehouseState: Department;
  warehouseCity: City;
  commission: string | null;
  bankInfo: BankInfo;
  elenasCommissionRules: (ElenasCommissionRule | null)[] | null;
  commissionRuleVariable: (CommissionRuleVariable | null)[] | null;
  auditedInformation: Boolean;
  address: Address;
  metabaseUrl: string | undefined;
  logoImgUrl: null | string;
  backgroundImgUrl: null | string;
  countMedalsMktp: number;
  totalMedalsMktp: number;
  statusMedals: string | null;
  integratedVendor: boolean;
};

export interface vendorMedals {
  title: string | null;
  dateLock: string | null;
  name: string | null;
  lock: boolean | null;
  key: string | null;
  warning: boolean | null;
  RangeValues: RangeValues | null;
  condition: string | null;
}

export interface RangeValues {
  min: number | null;
  max: number | null;
  value: number | null;
  valueFloat: number | null;
}

type VendorDocumentInput = {
  documentType: VendorDocumentType;
  documentBase64: string;
};

export type Department = {
  id: number;
  displayName: string;
  shortCode: string;
  cities: City[];
};

export type Bank = {
  id: number | null;
  name: string | null;
  countryCode: string | null;
};

export type City = {
  id: number;
  name: string;
  displayName: string;
};

export type BankInfo = {
  bankId: number | null;
  accountType: String | null;
  accountNumber: String | null;
  accountNumberBank: String | null;
};

type Brand = {
  id: number;
  name: string;
  description?: string | null;
  defaultProductCommission?: number | null;
  website?: string | null;
};

type Category = {
  id: number;
  name: string;
  parentId?: number | null;
};

type TopClient = {
  total: number;
  client: Client;
};

type User = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  secondLastName: string | null;
  cellphone: string;
  email: string;
  documentType: string;
  address: Address;
};

type Address = {
  streetAddress: string;
  innerNumber: string | null;
  outsideNumber: string | null;
  zipCode: string | null;
  colony: string | null;
  state: string;
  city: string;
  extraAddress: string | null;
  delegation: string | null;
};

type Client = {
  id: number;
  firstName: string | null;
  lastName: string | null;
};

type ClientFull = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  city: string | null;
  state: Department | null;
}

type Order = {
  id: number;
  createDate: string;
  number: string;
  trackingNumber: string | null;
  logisticProvider: string | null;
  guia: string | null;
  client: ClientFull | null;
  seller: Seller | null;
  newClientPrice: number;
  clientPrice: number;
  newTotal: number;
  total: number;
  newTax: number;
  vendorDiscount: number;
  elenasDiscount: number;
  tax: number;
  newShipping: number;
  shipping: number;
  newEarnings: number;
  earnings: number;
  currentStatus: OrderStatus;
  currentNewStatus: OrderStatusV2;
  paymentStatus: PaymentStatus;
  cartItems: (CartItem | null)[] | null;
  comments: string | null;
};

type OrderToPayments = {
  id: number;
  currentStatus: {
    status: string;
  };
};

type Seller = {
  id: number;
  firstName: string;
  lastName: string;
  cedula: string | null;
  address: string;
  city: string;
  cellphone: string;
  email: string;
};

type OrderStatus = {
  id: number;
  status: string;
  date: string;
  reason: string | null;
};

type OrderStatusV2 = {
  id: number;
  status: string;
  date: string;
  reason: string | null;
};

export type CartItem = {
  product: Product;
  newBasePrice: number;
  basePrice: number;
  quantity: number;
  newSubTotal: number;
  subTotal: number;
  shippingCost: number;
  newEarning: number;
  earning: number;
  newClientPrice: number;
  clientPrice: number;
};

export type Product = {
  id: number;
  name: string;
  brand?: Brand | null;
  category?: Category | null;
  description: string | null;
  sku?: string | null;
  newBasePrice: number;
  basePrice: number;
  image: ProductImage | null;
  images?: (ProductImage | null)[] | null;
  availableQuantity: number | null;
  visible?: boolean | null;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  guidesNumber?: number | null;
  dimensions: string | null;
  guarantee: string | null;
  techReport?: string | null;
  reviewSummary?: { avg: number } | null;
  elenasCommissionPercent?: number;
  typeProduct?: string;
  variants: Variants[];
};

type Variants = {
  name: string;
  details: VariantsDetails[];
};

type VariantsDetails = {
  id: number;
  description: string;
};

export type ProductReview = {
  id: number;
  text: string | null;
  rating: number;
  images: (ProductImage | null)[] | null;
  liked: boolean;
  usefulCount: number;
  reviewer: { firstName: string; lastName: string };
};

type ProductImage = {
  id: number;
  url: string | null;
};

type VendorReport = {
  file_url: string | null;
  vendor_id: number | null;
  accounting_cut_date: any | null;
};

export type VendorReportList = {
  createdAt: string;
  s3Url: string;
  available: boolean;
};
export type ProductVariantValues = {
  name: string;
  label: string;
  values: Array<string>;
  enabled: boolean;
};
export function gqlToDepartment(gql: any): Department {
  const cities = gql.cities.map((city: City) => {
    return { id: city.id, name: city.name, displayName: city.displayName };
  });
  return {
    id: gql.id,
    displayName: gql.displayName,
    shortCode: gql.shortCode,
    cities: cities,
  };
}

export function gqlToWarehouseAddress(gql: any) {
  if (gql.warehouseCity != null) {
    gql.warehouseCity = gql.warehouseCity.id;
  }
  if (gql.warehouseState != null) {
    gql.warehouseState = gql.warehouseState.id;
  }
  return gql;
}
