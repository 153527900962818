import React, { Suspense } from "react";
import { Layout } from "antd";
import styles from "./base.style";
import { Switch, Redirect, Route } from "react-router-dom";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer.index";
import stylesGlobal from "../../assets/styles";
import { Preloader } from "../../components/elements";
import { routes } from "../../utils/routes";
import { useOvermind } from "../../overmind";
const BasePage = () => {
  const { Header, Content } = Layout;
  const { state } = useOvermind();
  const {
    flags: {
      loading,
      all: { mkt_maintenance_mode },
    },
  } = state;

  /* Checking if the value of the variant is undefined. If it is, it will return the value of "off". If
  it is not, it will return the value of the variant. */
  const valueVariant =
    typeof mkt_maintenance_mode.variant === "undefined"
      ? "off"
      : mkt_maintenance_mode.variant;

  /* Checking if the maintenance mode is on or off. */
  const mktMaintenanceMode = valueVariant === "off";
 
  const GetRoutes = () => {
    /* Filtering the routes array and returning the routes that are not in maintenance mode. */
    const arrayRoutes = routes
      .filter((item) =>
        !mktMaintenanceMode ? item.maintenance : !item.maintenance
      )
      .map((route, idx) => {
        return (
          <Route key={idx} exact={route.exact} path={route.route}>
            {route.component}
          </Route>
        );
      });
    /* Adding the 404 page to the routes array. */
    const routeNotFoundPage = routes
      .filter((item) => item.name === "No Found Page")
      .map((route, idx) => {
        return (
          <Route key={idx} exact={route.exact} path={route.route}>
            {route.component}
          </Route>
        );
      });
    arrayRoutes.push(routeNotFoundPage);
    /* Returning the array of routes. */
    return arrayRoutes;
  };
  /* Checking if the flags are loading. If they are, it will return the preloader. */
  if (loading)
    return (
      <Layout className="layout">
        <Content style={styles.mainContainer}>
          <div style={stylesGlobal.wrapper}>
            <Preloader tip="Cargando y actualizando flags" />
          </div>
        </Content>
      </Layout>
    );

  /* Checking if the maintenance mode is on or off. If it is off, it will return the first layout. If
  it is on, it will return the second layout. */
  return !mktMaintenanceMode ? (
    <Layout className="layout">
      <Content style={styles.mainContainerMaintenance}>
        <Suspense
          fallback={
            <div style={stylesGlobal.wrapper}>
              <Preloader />
            </div>
          }
        >
          <Switch>
            <Redirect path="/protected" to="/protected/maintenance" exact />
            {GetRoutes()}
          </Switch>
        </Suspense>
      </Content>
      <Footer />
    </Layout>
  ) : (
    <div>
      <Layout className="layout">
        <Header style={styles.header}>
          <Nav />
        </Header>
        <Content style={styles.mainContainer}>
          <Suspense
            fallback={
              <div style={stylesGlobal.wrapper}>
                <Preloader />
              </div>
            }
          >
            <Switch>
              <Redirect path="/protected" to="/protected/products" exact />
              {GetRoutes()}
            </Switch>
          </Suspense>
        </Content>
        <Footer />
      </Layout>
    </div>
  );
};

export default BasePage;
