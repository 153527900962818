import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../constants/colors";

const H1Link = styled(Link)`
  text-transform: capitalize;
  color: ${colors.pacific_50};
  font-size: 14;
  text-decoration: underline;
`;
export const NameColumn = ({ to, name }: { to: string; name: string }) => {
  return <H1Link to={to}>{name}</H1Link>;
};
