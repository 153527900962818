export default {
  authContainer: { textAlign: "center", padding: "0 16px" },
  innerContainer: {
    margin: "0 auto",
    maxWidth: 412,
  },
  marketplaceLogo: {
    color: "#fff",
  },
  header: {
    backgroundColor: "#5b2ad0",
    background: "linear-gradient(45deg, #5b2ad0 0%, #5b2ad0 2%, #0089ff 100%)",
    paddingTop: 16,
    paddingBottom: 180,
    height: "auto",
    textAlign: "center",
  },
};
