// @ts-nocheck
import { AsyncAction } from "overmind";
import { OrderAlertsVariables } from "../effects/api/graphql-types/OrderAlerts";
import {
  GenerateOrdersReportVariables,
  MassiveGuideDownloadVariables,
  MassiveGuideListVariables,
  OrdersVariables,
} from "../effects/api/graphql-types/Orders";
import {
  UpdateOrderToDispatchedVariables,
  UpdateOrderToDispatched_updateOrderToDispatched,
} from "./graphql-types/UpdateOrderToDispatched";
import {
  UpdateOrderToProcessVariables,
  UpdateOrderToProcess_updateOrderToProcess,
} from "./graphql-types/UpdateOrderToProcess";

import moment from "moment";

export const fetchOrders: AsyncAction<OrdersVariables, void> = async (
  { state, actions, effects },
  variables
) => {
  try {
    if (
      state.orderIds.length &&
      variables.orderId === state.searchOrderId &&
      variables.orderStatus === state.orderStatusFilter &&
      variables.paymentStatus === state.paymentStatusFilter &&
      variables.page === state.ordersPage &&
      variables.trackingNumber === state.searchTrackingNumber
    ) {
      return;
    }

    state.loadingOrders = true;
    
    const { orders } = await effects.api.queries.getOrders(variables);
    state.loadingOrders = false;
    const ordersMap = orders.results.map((order) => {
      return {
        id: order.id,
        comments: order.comments,
        createDate: order.createDate,
        number: order.number,
        trackingNumber: order.trackingNumber,
        logisticProvider: order.logisticProvider,
        guia: order.guia,
        client: order.client,
        seller: {
          ...order.seller.user,
          address: order.seller.user.address
            ? order.seller.user.address.streetAddress
            : "",
          city: order.seller.user.address ? order.seller.user.address.city : "",
        },
        total: order.newTotal,
        clientPrice: order.clientPrice,
        tax: order.newTax,
        sku: order.sku,
        vendorDiscount: order.vendorDiscount,
        elenasDiscount: order.elenasDiscount,
        shipping: order.newShipping,
        earnings: order.newEarnings,
        currentStatus: order.currentStatus,
        currentNewStatus: order.currentNewStatus,
        paymentStatus: order.paymentStatus,
        cartItems: order.cartItems,
        product: order.cartItems[0].product.name,
        quantity: order.cartItems.length,
      };
    });
    state.orderIds = [];
    state.orders = {
      ...state.orders,
      ...Object.assign(
        {},
        ...ordersMap.map((item) => ({ [item["id"]]: item }))
      ),
    };
    state.orderIds = ordersMap.map((item) => item.id);
    state.orderStatusFilter = variables.orderStatus;
    state.paymentStatusFilter = variables.paymentStatus;
    state.totalOrdersCount = orders.totalResults;
    state.ordersPage = orders.currentPage;
    state.searchOrderId = variables.orderId;
    state.searchTrackinNumber = variables.trackingNumber;
  } catch (error) {
    state.loadingOrders = false;
    console.error("(FetchOrders) -----> Error: ", error);
  }
};

export const fetchOrderDetails: AsyncAction<void, void> = async (
  { state, effects },
  variables
) => {
  try {
    state.loadingOrders = true;
    const { orderDetails } = await effects.api.queries.getOrdersDetails();
    state.loadingOrders = false;
    const titles = [
      "ORDEN",
      "PROVEEDOR LOGÍSTICO",
      "GUIA",
      "ESTADO",
      "CANTIDAD",
      "PRODUCTO",
      "COMENTARIOS",
      "FECHA",
      "SKU",
      "PRECIO BASE",
      "GANANCIAS",
      "EMBAJADORA",
      "CEDULA",
      "DIRECCIÓN",
      "CIUDAD",
      "CELULAR",
      "EMAIL",
    ];
    const data = orderDetails.map((item) => [
      item?.id,
      item?.logisticProvider,
      item?.guia,
      item?.status,
      item?.quantity,
      item?.productName,
      item?.comments,
      moment(item?.date).locale('es').format("YYYY-MM-DD"),
      item?.sku,
      item?.basePrice,
      item?.earnings,
      item?.seller,
      item?.identification,
      item?.address,
      item?.city,
      item?.phone,
      item?.email,
    ]);
    state.orderDetails = [titles, ...data];
  } catch (error) {
    state.loadingOrders = false;
    console.error("(FetchOrderDetails) -----> Error: ", error);
  }
};

export const updateOrderToProcess: AsyncAction<
  UpdateOrderToProcessVariables,
  UpdateOrderToProcess_updateOrderToProcess
> = async ({ state, effects }, variables) => {
  try {
    const { updateOrderToProcess } =
      await effects.api.mutations.updateOrderToProcess(variables);
    if (updateOrderToProcess.__typename === "Order") {
      const order = { ...state.orders[variables.id] };
      order.currentNewStatus.status =
        updateOrderToProcess.currentNewStatus.status;
      state.orders[variables.id] = order;
    }

    return updateOrderToProcess;
  } catch (error) {
    console.error("(updateOrdertoProcess) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const updateOrderToDispatched: AsyncAction<
  UpdateOrderToDispatchedVariables,
  UpdateOrderToDispatched_updateOrderToDispatched
> = async ({ state, effects }, variables) => {
  try {
    const { updateOrderToDispatched } =
      await effects.api.mutations.updateOrderToDispatched(variables);
    if (updateOrderToDispatched.__typename === "Order") {
      const order = { ...state.orders[variables.id] };
      order.currentNewStatus.status =
        updateOrderToDispatched.currentNewStatus.status;
      state.orders[variables.id] = order;
    }

    return updateOrderToDispatched;
  } catch (error) {
    console.error("(updateOrderToDispatched) -----> Mutation error: ", error);
    return {
      __typename: "ValidationErrors",
      message: "Ocurrió un error",
      errors: [],
    };
  }
};

export const fetchOrderAlerts: AsyncAction<OrderAlertsVariables, void> = async (
  { state, effects },
  variables
) => {
  try {
    const { orderAlerts } = await effects.api.queries.getOrderAlerts(variables);
    state.lateOrderIds = orderAlerts.results
      .filter((item) => item.alertType === "late_order")
      .map((item) => item.order.id);
    state.dangerOrderIds = orderAlerts.results
      .filter((item) => item.alertType === "danger_late_order")
      .map((item) => item.order.id);
    state.newOrderIds = orderAlerts.results
      .filter((item) => item.alertType === "new_order")
      .map((item) => item.order.id);
    orderAlerts.results.forEach((item) => {
      if (state.orders[item.order.id]) {
        return;
      }
      state.orders[item.order.id] = {
        id: item.order.id,
        comments: item.order.comments,
        createDate: item.order.createDate,
        number: item.order.number,
        trackingNumber: item.order.trackingNumber,
        logisticProvider: item.order.logisticProvider,
        guia: item.order.guia,
        client: item.order.client,
        sku: item.order.sku,
        seller: {
          ...item.order.seller.user,
          address: item.order.seller.user.address
            ? item.order.seller.user.address.streetAddress
            : "",
          city: item.order.seller.user.address
            ? item.order.seller.user.address.city
            : "",
        },
        total: item.order.newTotal,
        clientPrice: item.order.newClientPrice,
        tax: item.order.newTax,
        shipping: item.order.newShipping,
        earnings: item.order.newEarnings,
        currentStatus: item.order.currentStatus,
        currentNewStatus: item.order.currentNewStatus,
        paymentStatus: item.order.paymentStatus,
        cartItems: item.order.cartItems,
        product: item.order.cartItems[0].product.name,
      };
    });
    state.totalLateOrders = orderAlerts.totalLateOrders;
    state.totalNewOrders = orderAlerts.totalNewOrders;
    state.totalDangerOrders = orderAlerts.totalDangerOrders;
    state.OrderAlertsPage = orderAlerts.currentPage;
  } catch (error) {
    console.error("(FetchOrderAlerts) -----> Error: ", error);
  }
};

export const getMassiveGuideDownload: AsyncAction<
  MassiveGuideDownloadVariables,
  void
> = async ({ effects }, variables) => {
  try {
    await effects.api.queries.getMassiveGuideDownload(variables);
  } catch (error) {
    console.error("(FetchMassiveGuideDownload) -----> Error: ", error);
  }
};

export const getMassiveGuideList: AsyncAction<
  MassiveGuideListVariables,
  void
> = async ({ state, effects }, variables) => {
  try {
    const { massiveGuideDownloadList } =
      await effects.api.queries.getMassiveGuideDownloadList(variables);
    state.guidesReports = massiveGuideDownloadList;
  } catch (error) {
    console.error("(getMassiveGuideDownloadList) -----> Error: ", error);
  }
};

export const fetchVendorReports: AsyncAction<void> = async ({
  state,
  effects,
}) => {
  try {
    const { massiveGuideDownloadList: guidesReports } =
      await effects.api.queries.getMassiveGuideDownloadList({
        report_type: "massive_guides",
      });
    const { massiveGuideDownloadList: ordersReports } =
      await effects.api.queries.getMassiveGuideDownloadList({
        report_type: "order_history",
      });
    state.guidesReports = guidesReports;
    state.ordersReports = ordersReports;
  } catch (error) {
    console.error("(getMassiveGuideDownloadList) -----> Error: ", error);
  }
};

export const generateOrdersReport: AsyncAction<
  GenerateOrdersReportVariables,
  void
> = async ({ effects }, variables) => {
  try {
    const { generateOrdersReport } =
      await effects.api.queries.generateOrdersReport(variables);

    return generateOrdersReport;
  } catch (error) {
    console.error("(generateOrdersReport) -----> Error: ", error);
  }
};
