import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import { TypesText } from "../@types";
export const RootText = styled.p<TypesText>`
  font-family: Nunito Sans;
  font-style: normal;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) =>
    props.color === "white"
      ? colors.white_100
      : props.color === "darkblue"
      ? colors.pacific_70
      : props.color === "gray"
      ? colors.stone_60
      : props.color === "blue"
      ? colors.pacific_50
      : props.color === "violet"
      ? colors.eva_50
      : colors.stone_80};
  ${(props) => props.strong && "font-weight: bold;"}
  ${(props) => props.underlined && "text-decoration: underline;"}
  ${(props) => props.cursorMouse && "cursor: pointer;"}
`;
