import React from "react";
import styled from "styled-components";

const RootRow = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Row: React.FC<{ id?: string; style?: React.CSSProperties }> = ({
  children,
  ...rest
}) => {
  return <RootRow {...rest}>{children}</RootRow>;
};

const RootColumn = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Column: React.FC<{ id?: string; style?: React.CSSProperties }> = ({
  children,
  ...rest
}) => {
  return <RootColumn {...rest}>{children}</RootColumn>;
};

const RootBox = styled("div")`
  padding: 0px; 
`;

const Box: React.FC<{ id?: string; style?: React.CSSProperties }> = ({
  children,
  ...rest
}) => {
  return <RootBox {...rest}>{children}</RootBox>;
};

export const Container = {
  Row,
  Column,
  Box,
};
